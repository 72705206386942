import React, { Fragment, useEffect } from "react";
import ServicesDetail from "../../Detail/ServicesDetails";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../CSS/IndexPage/Services.css";

const Services = () => {
  const detail = ServicesDetail;

  useEffect(() => {
    Aos.init({ duration: 2000, once: true, easing: "ease" });
  }, []);

  return (
    <Fragment>
      <section data-aos="fade-up" className="services">
        <div className="heading">
          <span>Uniqueness</span>
          <h2>We Bring To You</h2>
        </div>

        <div className="services-row">
          {detail.map((detail) => {
            return (
              <div className="services-card" key={Math.random()}>
                <div className="services-column">
                  <div className="content">
                    <div className="services-icon">
                      <img src={detail.Image} alt="icon" />
                    </div>
                    <div className="services-content">
                      <h3>{detail.heading}</h3>
                      <p>{detail.info}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Fragment>
  );
};

export default Services;
