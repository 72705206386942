import React from "react";
import "../../CSS/Appointments/PatientTestimonial.css";
import images from "../../constants/image";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import { FreeMode, Navigation } from "swiper";

const patients = [ 
  {
    id: 1,
    image: images.user2,
    name: " Asa Walter",
    Testimonial:
      "I am incredibly grateful for the exceptional care I received from Mindikko's psychiatrist. Their expertise and support were instrumental in my journey towards mental well-being.",
  },
  {
    id: 2,
    image: images.user3,
    name: "Eric Kamau",
    Testimonial:
      "I am incredibly grateful for the exceptional care I received from Mindikko's psychiatrist. Their expertise and support were instrumental in my journey towards mental well-being.",
  },
  {
    id: 3,
    image: images.user4,
    name: "Karen Dawnson",
    Testimonial:
      "I am incredibly grateful for the exceptional care I received from Mindikko's psychiatrist. Their expertise and support were instrumental in my journey towards mental well-being.",
  },
  {
    id: 4,
    image: images.user5,
    name: "John Doe",
    Testimonial:
      "I am incredibly grateful for the exceptional care I received from Mindikko's psychiatrist. Their expertise and support were instrumental in my journey towards mental well-being.",
  },
  {
    id: 5,
    image: images.user6,
    name: "Teresia Wairimu",
    Testimonial:
      "I am incredibly grateful for the exceptional care I received from Mindikko's psychiatrist. Their expertise and support were instrumental in my journey towards mental well-being.",
  },
  {
    id: 6,
    image: images.user2,
    name: "Jane Doe",
    Testimonial:
      "I am incredibly grateful for the exceptional care I received from Mindikko's psychiatrist. Their expertise and support were instrumental in my journey towards mental well-being.",
  },
];

const PatientTestimonial = () => {
  return (
    <div className='app-patient-testimanial-container'>
      <div className='app-patient-testimanial-header'>
        <h2>Our Patient Experiences</h2>
      </div>

      <div className='app-patient-testimanial-body'>
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          grabCursor={true}
          freeMode={true}
          // pagination={{
          //     clickable: true
          // }}
          navigation={true}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          modules={[FreeMode, Navigation]}
          className='my-swiper'
        >
          {patients.map((patient) => (
            <SwiperSlide
              key={patient.id}
              className='app-patient-testimanial-swiper'
            >
              <div className='app-patient-testimanial-card'>
                <div className='app-patient-testimanial-card-image'>
                  <img src={patient.image} alt='patient' />
                </div>
                <div className='app-patient-testimanial-card-body'>
                  <div className='app-patient-testimanial-card-body-name'>
                    <h3>{patient.name}</h3>
                  </div>
                  <div className='app-patient-testimanial-card-body-icon-left'>
                    <FaQuoteLeft className='quote-icon' />
                  </div>
                  <div className='app-patient-testimanial-card-body-txt'>
                    <p>{patient.Testimonial}</p>
                  </div>
                  <div className='app-patient-testimanial-card-body-icon-right'>
                    <FaQuoteRight className='quote-icon' />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PatientTestimonial;
