import React from "react";
import "../../CSS/AboutUs & ContactUs/About.css";
import storyImage from "../../images/story.webp";

const SecondBanner = () => {
  return (
    <div className="story">
      <div className="story-left">
        <div className="story-head">
          Discover the Path to Emotional Wellness with <span>Mindikko</span>
        </div>
        <img loading="lazy" src={storyImage} className="storyImg" alt="" />
      </div>
      <div className="story-right">
        <span>Our Story</span>
        <p>
          Mindikko is a platform created by XX, an engineering student who faced
          a difficult time in his life when he found himself dealing with a
          breakup and falling into a deep depression. The pressure of his
          studies and personal life became too much to bear, and he found
          himself turning to drugs and alcohol as a coping mechanism.
        </p>
        <p>
          But XX was not one to give up easily. He realized that his struggles
          were not unique and that many others were dealing with similar issues.
          He decided to use his experiences to help others who were going
          through the same thing.
        </p>
        <p>
          Along with a team of fellow engineering students, XX he decided to
          create a platform to offer support and resources to those struggling
          with depression and trauma. He went from being a victim of his own
          circumstances to a source of inspiration for others. By sharing his
          story and offering his support, XX has made a positive impact on the
          lives of countless individuals who are now on the path to recovery.
        </p>
        <p>
          Mindikko is that effort he created to change lives. At
          Mindikko, we believe that no one should have to face their struggles
          alone. We are dedicated to helping those in need and providing a
          community of support and inspiration. Join us on this journey and let
          us help you find hope and a brighter tomorrow.
        </p>
        <div className="counter"></div>
      </div>
    </div>
  );
};

export default SecondBanner;
