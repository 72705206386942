import React, { Fragment } from "react";
import PropertyDetail from "../../Detail/PropertyDetail";
import "../../CSS/IndexPage/Property.css";

const Property = () => {
  const detail = PropertyDetail;

  return (
    <Fragment>
      <section className="property">
        <div className="center">
          <h3>The Smartest Way To Hire A Mentor</h3>
        </div>

        <div className="row">
          {detail.map((detail) => {
            return (
              <div className="column" key={Math.random()}>
                <div className="single-property">
                  <div className="card">
                    <div className="property-thumb">
                      {/* <div className="property-tag"> For Sale </div> */}
                      <img src={detail.Image} alt="Palace" />
                    </div>

                    <div className="property-content">
                      <h3>{detail.heading}</h3>
                      <div className='mark'></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="more-property">
          <a className="property-btn" href="#1">
            More specialisations
          </a>
        </div>
      </section>
    </Fragment>
  );
};

export default Property;
