const WorkDetail = [
    {
        id:"1",
        number:"01",
        heading:"Take A Test",
        info:"Our test is calibrated by an AI powered algorithm that will help you understand better"
    },
    {
        id:"2",
        number:"02",
        heading:"Talk To An Expert",
        info:"Our experts are available 24/7. On top of that we have a dedicated team to assist you"
    },
    {
        id:"3",
        number:"03",
        heading:"Participate in Activities",
        info:"We provide you the opportunity to cultivate good habits and keeping a tab on things in life like writing journals."
    },
    {
        id:"4",
        number:"04",
        heading:"Review the Progress",
        info:"Reviewing the progress often includes revisiting the strategies especially in the changing scenarios of life."
    },
]
export default WorkDetail