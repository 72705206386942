import React, { useState } from 'react';

export const NavbarContext = React.createContext();

const NavbarContextProvider = ({ children }) => {
    const [transitionNavbar, setTransitionNavbar] = useState(false);

    const navbarContextValue = {
      transitionNavbar,
      setTransitionNavbar,
    };

    return (
        <NavbarContext.Provider value={navbarContextValue}>
            {children}
        </NavbarContext.Provider>
    );
};

export default NavbarContextProvider;
