import React, { useState, useRef, useContext } from "react";
import "../../CSS/Login&SignUp/index.css";
import axios from "../../axios/index";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import AuthContext from "../../Context/authcontext";

export default function SignUp({ openLogin, onClose }) {
  const [visible, setVisibility] = useState(false);
  const [focused, setFocused] = useState(false);
  const { setIsAuth } = useContext(AuthContext);

  const user = useRef({
    firstname: "",
    lastname: "",
    phoneNumber: "",
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    user.current[inputName] = inputValue;
  };

  const [errors, setError] = useState("");
  const [signupLoading, setSignupLoading] = useState(false);

  const handleSubmit = async (e) => {
    setSignupLoading(true);
    e.preventDefault();
    try {
      const res = await axios.post("/user/signup", {...user.current, email: user.current.email.toLowerCase()});
      sessionStorage.setItem("user", JSON.stringify(res.data));
      setIsAuth(true);
      onClose();
    } catch (error) {
      setSignupLoading(false);
      setError(error.response.data.error);
      setTimeout(() => {
          setError('');
      }, 3000);
    }
  };
  return (
    <>
      <div className="body">
        <div className="box" style={{ height: "660px" }}>
          <button onClick={onClose} className="close-btn">
            <i className="fa fa-times"></i>
          </button>
          <form onSubmit={handleSubmit}>
            <h2>Create an account </h2>
            <div className="inputBox">
              <input
                type='text'
                required='required'
                name='firstname'
                minLength='1'
                maxLength='30'
                onInput={handleInput}
              />
              <span>First Name</span>
              <i></i>
            </div>
            <div className="inputBox">
              <input
                type='text'
                required='required'
                name='lastname'
                minLength='1'
                maxLength='30'
                onInput={handleInput}
              />
              <span>Last Name</span>
              <i></i>
            </div>
            <div className="inputBox">
              <input
                type="tel"
                required="required"
                name="phoneNumber"
                minLength="4"
                maxLength="17"
                onInput={handleInput}
              />
              <span>Phone number</span>
              <i></i>
            </div>

            <div className="inputBox">
              <input
                type="email"
                required="required"
                name="email"
                onInput={handleInput}
              />
              <span>Email</span>
              <i></i>
            </div>
            <div className="inputBox">
              <input
                type={!visible ? "password" : "text"}
                required='required'
                name='password'
                onInput={handleInput}
                onFocus={() => setFocused(true)}
              />
              <span>Password</span>
              {focused &&
                (!visible ? (
                  <AiFillEye onClick={() => setVisibility(!visible)} />
                ) : (
                  <AiFillEyeInvisible onClick={() => setVisibility(!visible)} />
                ))}
              <i></i>
            </div>
            <div className="links">
              <p>Already a Member ?</p>
              <button type="button" onClick={openLogin}>
                Sign In
              </button>
            </div>
            <small style={{ color: "red", textAlign: "center" }}>
              {errors}
            </small>
            <input
              type="submit"
              value={signupLoading ? "Signing Up....." : "Sign Up"}
            />
          </form>
        </div>
      </div>
    </>
  );
}
