
import React from "react";
// import AppointmentModal from "../Components/Appointments/AppointmentModal";
import HomePage from "../Components/Appointments/Homepage";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../Context/authcontext";

const Appointment = () => {
  const navigate = useNavigate();
  const { isAuth } = useContext(AuthContext);
  if (!isAuth) {
    navigate("/");
  }
  return (
    <>
      <HomePage />
    </>
  );
}

export default Appointment;
