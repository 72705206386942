import { Outlet } from "react-router-dom";

import Footer from "./Components/Layout/Footer";
import Navbar from "./Components/Layout/Navbar";

export default function Layout() {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
