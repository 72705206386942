import React, { useEffect, useState } from "react";
import { Table, Input, Radio, Tooltip, Button } from "antd";
import { DeleteOutlined, PhoneOutlined } from "@ant-design/icons";
import axios from "../../axios/index";
import moment from "moment";

const Appointments = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const { token } = JSON.parse(sessionStorage.getItem("admin"));

  useEffect(() => {
    axios
      .get("/admins/appointments", {
        headers: { Authorization: `Bearer ${token} ` },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData([]);
      });
  }, [token]);

  const handleDelete = (record) => {
    axios
      .delete(`/admins/appointments/${record._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData((prevData) => {
          const filteredData = prevData.filter(
            (post) => post._id !== res.data._id
          );
          return filteredData;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredData = data
    .filter((item) => {
      const currentDate = moment().startOf("day");

      if (filter === "Cancelled") {
        return item.isCancel;
      } else if (filter === "Past") {
        const appointmentDate = moment(item.date);
        return appointmentDate.isBefore(currentDate);
      } else if (filter === "Upcoming") {
        const appointmentDate = moment(item.date);
        return appointmentDate.isSameOrAfter(currentDate);
      }

      return true;
    })
    .filter((item) => {
      const searchFields = [
        item.doctorName,
        item.patientName,
        item.date,
        item.reason,
      ];
      const searchString = searchValue.toLowerCase();
      return searchFields.some(
        (field) =>
          field && field.toString().toLowerCase().includes(searchString)
      );
    });

  const columns = [
    {
      title: "Doctor's Name",
      dataIndex: "doctorName",
      key: "doctorName",
      width: 150,
      ellipsis: true,
    },
    {
      title: "patientName",
      dataIndex: "patientName",
      key: "patientName",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
      render: (time) => (
        <Tooltip placement='topLeft' title={time}>
          {time}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "phone",
      key: "phone",
      render: (_, record) => (
        <>
          <Tooltip placement='topRight' title={record.phone}>
            <Button
              size='small'
              type='primary'
              onClick={() => alert(record.date)}
            >
              <PhoneOutlined />
            </Button>
            &ensp;
          </Tooltip>
          {!record.isCancel && (
            <Tooltip placement='topRight' title='Delete'>
              <Button
                size='small'
                type='primary'
                danger
                onClick={() => handleDelete(record)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {/* Header components */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Radio.Group
          options={["All", "Past", "Upcoming", "Cancelled"]}
          optionType='button'
          buttonStyle='solid'
          defaultValue='All'
          onChange={handleFilterChange}
        />
        <Input
          style={{ maxWidth: "300px" }}
          placeholder='Search by Name, Email, Phone or Address'
          value={searchValue}
          onChange={handleSearch}
        />
      </div>
      <br />
      {/* Body content of appointments */}
      <Table
        columns={columns}
        dataSource={filteredData}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.description}
            </p>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </>
  );
};

export default Appointments;
