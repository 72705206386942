import { useState } from "react";
import AuthContext from "./authcontext";
import { useEffect } from "react";
import axios from "../axios";
import { useNavigate } from "react-router-dom";

const AuthContextProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  
  const authContextValue = {
    isAuth,
    setIsAuth,
  };

  useEffect(() => {
     const user = sessionStorage.getItem("user") || null;
    if (user) {
      axios
        .get("/user/verify-auth", {
          headers: { Authorization: `Bearer ${JSON.parse(user).token}` },
        })
        .then(() => {
          setIsAuth(true);
        })
        .catch(() => {
          setIsAuth(false);
          sessionStorage.removeItem("user");
          navigate("/");
        });
    }
  }, [navigate]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
