import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Radio, Table, Modal, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

export default function Blogs() {
  const navigate = useNavigate();
  const { username, token } = JSON.parse(sessionStorage.getItem("admin"));
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("All");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [editingPost, setEditingPost] = useState(null); // Track the post being edited
  const blogPost = useRef({
    title: "",
    content: "",
  });

  useEffect(() => {
    axios
      .get("/api/blogs")
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        setData([]);
      });
  }, [token]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setEditingPost(null);
    setTitle("");
    setContent("");
  };

  const handleModalPost = () => {
    if (content.trim() === "" || title.trim() === "") {
      handleModalCancel();
      return;
    }

    const newBlog = {
      title: title.trim(),
      content: content.trim(),
    };

    if (editingPost) {
      axios
        .put(`/api/blogs/${editingPost._id}`, newBlog, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const updatedData = data.map((post) =>
            post._id === editingPost._id ? res.data : post
          );
          setData(updatedData);
          handleModalCancel();
        })
        .catch(() => {
          handleModalCancel();
        });
    } else {
      axios
        .post("/api/blogs", newBlog, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setData([...data, res.data]);
          handleModalCancel();
        })
        .catch(() => {
          handleModalCancel();
        });
    }
  };

  const handleEdit = (record) => {
    setEditingPost(record);
    setTitle(record.title);
    setContent(record.content);
    setShowModal(true);
  };

  const handleDelete = (record) => {
    axios
      .delete(`/api/blogs/${record._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const filteredData = data.filter((post) => post._id !== res.data._id);
        setData(filteredData);
        //setData([...data, res.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    blogPost.current[inputName] = inputValue;
  };

  const filteredData = data.filter((item) => {
    if (filter === "My Blog") {
      return item.author === username;
    }
    return true;
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "author",
      dataIndex: "author",
      key: "author",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "content",
      dataIndex: "content",
      key: "content",
      width: 450,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <Button size='small' onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
          &ensp;
          <Button
            size='small'
            type='primary'
            danger
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Radio.Group
          options={["All", "My Blog"]}
          optionType='button'
          buttonStyle='solid'
          defaultValue={"All"}
          onChange={handleFilterChange}
        />
        <Button type='primary' onClick={() => setShowModal(true)}>
          <PlusOutlined /> Add New
        </Button>
      </div>
      <br />
      <Table
        columns={columns}
        dataSource={filteredData.map((item, index) => ({
          ...item,
          key: index.toString(),
        }))}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.content}</p>
          ),
          expandIconColumnIndex: -1,
          expandRowByClick: true,
        }}
      />

      <Modal
        style={{
          fontStyle: "bold",
          borderRadius: "20px",
          padding: "35px",
          fontWeight: 700,
          color: "white",
          bottom: 300,
        }}
        closable={false}
        open={showModal}
        onCancel={handleModalCancel}
        footer={[
          <Button
            key='cancel'
            style={{ zIndex: 1000 }}
            onClick={handleModalCancel}
          >
            Cancel
          </Button>,
          <Button
            key='post'
            style={{ zIndex: 1000 }}
            type='primary'
            onClick={handleModalPost}
          >
            Post
          </Button>,
        ]}
        contentStyle={{ backgroundColor: "transparent" }}
      >
        <Form.Item label='Title'>
          <Input
            name='title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onInput={handleInput}
          />
        </Form.Item>
      </Modal>
    </>
  );
}
