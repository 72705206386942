import React, { useContext, useState, useEffect } from "react";
import "../../CSS/Appointments/DoctorsProfile.css";
import { BsTelephoneOutbound } from "react-icons/bs";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import DoctorContext from "../../Context/doctorContext";
import ModalContext from "../../Context/modalContext";
import AppointmentModal from "./AppointmentModal";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router-dom";

const DoctorsProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { doctor, setDoctor } = useContext(DoctorContext);
  const { setIsOpen } = useContext(ModalContext);
  const [doctors, setDoctors] = useState([]);

  const fetchDoctors = async () => {
    try {
      const response = await axios.get("/api/doctors");
      setDoctors(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDoctor = async () => {
    try {
      const response = await axios.get(`/api/doctors/${id}`);
      setDoctor(response.data);
    } catch (error) {
      navigate("/appointment");
    }
  };

  useEffect(() => {
    fetchDoctors();
    fetchDoctor();
  }, []);

  return (
    <>
      <AppointmentModal doctors={doctors} selectedDoctor={doctor} />
      <div className='app-profile-content'>
        <div className='app-profile-content-header'>
          <div className='app-profile-content-header-left'>
            <div>
              <img src={doctor.imageUrl} alt='profile' />
            </div>
          </div>
          <div className='app-profile-content-header-left-name'>
            <h3>{doctor.name}</h3>
            <div className='doctor-address'>
              <p className='address-icon'>
                <SlLocationPin />
              </p>
              <p className='address-location'>{doctor.address}</p>
            </div>
            <p>{doctor.specialization}</p>
          </div>
        </div>
        {/* biography section */}
        <div className='app-profile-content-biography'>
          <div className='app-profile-content-biography-header'>
            <h3>Biography</h3>
            <p>{doctor.bio}</p>
          </div>
          <div className='app-profile-content-biography-contact'>
            <h3>Contact</h3>
            <div className='app-profile-content-biography-contact-one'>
              <div className='app-profile-content-biography-contact-one-left'>
                <p>
                  <BsTelephoneOutbound />
                </p>
                <p>
                  <MdOutlineMarkEmailRead />
                </p>
              </div>
              <div className='app-profile-content-biography-contact-one-right'>
                <p>{doctor.phoneNumber}</p>
                <p>{doctor.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='app-profile-content-header-left-btn'>
          <button type='button' onClick={() => setIsOpen(true)}>
            Book Session
          </button>
        </div>
      </div>
    </>
  );
};

export default DoctorsProfile;
