import axios from "../../axios/index";
import React, { useState, useRef, useContext } from "react";
import "../../CSS/Login&SignUp/index.css";
import AuthContext from "../../Context/authcontext";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = ({ onClose, openSignup }) => {
  const user = useRef({
    email: "",
    password: "",
  });

  // this function for auto hide error
  setTimeout(() => {
    setError("");
  }, 5000);

  const [error, setError] = useState("");
  const [focused, setFocused] = useState(false);
  const [visible, setVisibility] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState({});
  const { setIsAuth } = useContext(AuthContext);

  const handleInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    user.current[inputName] = inputValue;
  };

  const handleCheckEmail = () => {
    setEmailLoading(true);
    axios
      .post("/user/email-verification", {
        email: user.current.email.toLowerCase(),
      })
      .then((res) => {
        setEmailLoading(false);
        setEmailVerified(true);
        setVerifiedUser(res.data);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setEmailVerified(false);
        setEmailLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    setLoginLoading(true);
    e.preventDefault();
    try {
      const url = "/user/login";
      const res = await axios.post(url, user.current);
      sessionStorage.setItem("user", JSON.stringify(res.data));
      setLoginLoading(false);
      setIsAuth(true);
      onClose();
    } catch (error) {
      setLoginLoading(false);
      setError(error.response.data.error);
    }
  };

  const handleGoogleAuth = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  return (
    <div className='body'>
      <div className='box'>
        <button onClick={onClose} className='close-btn'>
          <i className='fa fa-times'></i>
        </button>
        <form autoComplete='off'>
          <h2>{!emailVerified ? 'Enter Your Email' : 'Login'}</h2>
          {!emailVerified && (
            <>
              <div className='inputBox'>
                <input type='email' name='email' onInput={handleInput} required />
                <span>Email</span>
                <i></i>
              </div>
              {error && <div className='errormessage'>{error}</div>}
              <input
                type='button'
                onClick={handleCheckEmail}
                value={emailLoading ? "Checking..." : "Continue"}
              />
            </>
          )}

          {emailVerified && (
            <>
              <div
                style={{
                  background: `url(${verifiedUser.image}) 50% 50% / 100% no-repeat`,
                  borderRadius: "50%",
                  width: "20%",
                  height: "13%",
                  margin: "auto",
                }}
              />
              <div className='inputBox' style={{ marginTop: 0 }}>
                <input
                  type='email'
                  name='email'
                  required
                  value={verifiedUser.email}
                  disabled
                  style={{
                    padding: "20px 10px 10px",
                    background: "transparent",
                    color: "#fdae5c",
                  }}
                />
                <span
                  style={{
                    color: "grey",
                    transform: "translateX(0) translateY(-26px)",
                    fontSize: 0.75 + "em",
                  }}
                >
                  Email
                </span>
                <i></i>
              </div>
              <div className='links' style={{ marginBottom: -32 + "px" }}>
                <p></p>
                <button type='button' onClick={() => setEmailVerified(false)}>
                  Not You?
                </button>
              </div>

              <div className='inputBox'>
                <input
                  type={!visible ? "password" : "text"}
                  required='required'
                  name='password'
                  onFocus={() => setFocused(true)}
                  onInput={handleInput}
                />
                <span>Password</span>
                {focused &&
                  (!visible ? (
                    <AiFillEye onClick={() => setVisibility(!visible)} />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setVisibility(!visible)}
                    />
                  ))}
                <i></i>
              </div>
              {error && <div className='errormessage'>{error}</div>}
              <input
                type='submit'
                onClick={handleSubmit}
                value={loginLoading ? "Logging in..." : "Login"}
              />
            </>
          )}

          <br />

          <div className='links'>
            <p>New Here ?</p>
            <button type='button' onClick={openSignup}>
              Sign Up
            </button>
          </div>

          <p style={{ textAlign: "center", margin: "15px 0px" }}>
            <span style={{ color: "gray" }}> OR </span>
          </p>
          <br />
          <div className='social-login-btn'>
            <i className='fa fa-google' onClick={handleGoogleAuth}></i>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
