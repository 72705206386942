const ServicesDetail = [
  {
    id: "1",
    Image: "./Services-Images/wallet.png",
    heading: "Affordability",
    info: "Best service without compromising on the quality or affordability.",
  },
  {
    id: "2",
    Image: "./Services-Images/piggy-bank.png",
    heading: "Comfort",
    info: "You get best value for money , you can choose time slots at yourown comfort",
  },
  {
    id: "3",
    Image: "./Services-Images/document.png",
    heading: "Experienced faculties",
    info: "Our psychiatrists and psychologists have a proven track record of merit.",
  },
  {
    id: "4",
    Image: "./Services-Images/unlock.png",
    heading: "Privacy",
    info: "We are deeply committed to preserving your privacy in letter and spirit.",
  },
];
    export default ServicesDetail