import React, { Fragment, useContext, useEffect, useState } from "react";
import "../../CSS/IndexPage/Home.css";
import AuthContext from "../../Context/authcontext";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModalContext from "../../Context/LoginModalContext";
import { NavbarContext } from "../../Context/navbarContext";

const Home = () => {
  const { setModal } = useContext(LoginModalContext);
  const { setTransitionNavbar } = useContext(NavbarContext);
  const { isAuth } = useContext(AuthContext);
  const [hovered, setHovered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setTransitionNavbar(false);
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const userParam = searchParams.get("user");

    if (userParam) {
      // Decode and parse the user information
      const decodedUser = decodeURIComponent(userParam);
      sessionStorage.setItem("user", decodedUser);
      navigate("/");
    }
  }, [location.search, navigate, setTransitionNavbar]);

  return (
    <Fragment>
      <section className='home'>
        <div className='container'>
          <h1>
            Your Secret <br /> Mentor
          </h1>
          <p>Ingenious Algorithm towards your mindfulness</p>
          <div className='link'>
            {isAuth ? (
              <>
                <a
                  href='/profile'
                  className='appointment'
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  style={
                    hovered
                      ? { backgroundColor: "transparent", color: "#fdae5c" }
                      : { backgroundColor: "#fdae5c", color: "#fff" }
                  }
                >
                  View Profile
                </a>
                <a
                  href='/appointment'
                  className='appointment'
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  style={
                    hovered
                      ? { backgroundColor: "#fdae5c", color: "#fff" }
                      : { backgroundColor: "transparent", color: "#fdae5c" }
                  }
                >
                  Book Appointment
                </a>
              </>
            ) : (
              <a
                href='##'
                className='appointment'
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => setModal("signup")}
                style={
                  hovered
                    ? { backgroundColor: "transparent", color: "#fdae5c" }
                    : { backgroundColor: "#fdae5c", color: "#fff" }
                }
              >
                Try Mindikko
              </a>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Home;
