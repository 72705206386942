import React, { Fragment, useState } from "react";
import "../../CSS/Layout/Navbar.css";
import Signup from "../Modal/Signup";
import Login from "../Modal/Login";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Context/authcontext";
import axios from "../../axios";
import { logo } from "../../assets/images";
import LoginModalContext from "../../Context/LoginModalContext";
import { useContext } from "react";
import { NavbarContext } from "../../Context/navbarContext";

const Navbar = () => {
  const { modal, setModal } = React.useContext(LoginModalContext);
  const [showMenu, setShowMenu] = useState(false);
  const { transitionNavbar, setTransitionNavbar } = useContext(NavbarContext);
  const navigate = useNavigate();

  const { isAuth, setIsAuth } = React.useContext(AuthContext);

  const user = JSON.parse(sessionStorage.getItem("user")) || "";

  const changeBackground = () => {
    setShowMenu(false);
    if (window.scrollY >= 150) {
      setTransitionNavbar(true);
    } else {
      setTransitionNavbar(false);
    }
  };

  const getProfile = () => {
    axios
      .get("/user/profile", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        navigate("/profile", { state: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    setIsAuth(false);
    navigate("/");
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <Fragment>
      <nav className={transitionNavbar ? "navbar active" : "navbar"}>
        <button type='button' onClick={() => navigate("/")} id='logo-btn'>
          <img src={logo} alt='logo' />
        </button>
        <div className={showMenu ? "menu  mobile-menu" : "menu"}>
          <ul>
            <div
              className='auth-nav'
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              {isAuth ? (
                <ul style={{ flexDirection: "column" }}>
                  <li>
                    <button type='button' onClick={getProfile}>
                      Profile
                    </button>
                  </li>
                  <li>
                    <button type='button' onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
              ) : (
                <ul style={{ flexDirection: "column" }}>
                  <li>
                    <button type='button' onClick={() => setModal("signup")}>
                      Signup
                    </button>
                  </li>
                  <li>
                    <button type='button' onClick={() => setModal("login")}>
                      Login
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <li>
              <button type='button' onClick={() => navigate("/")}>
                Home
              </button>
            </li>
            <li>
              <button type='button' onClick={() => navigate("/aboutus")}>
                About Us
              </button>
            </li>
            <li>
              <button type='button' onClick={() => navigate("/contact")}>
                Contact Us
              </button>
            </li>
            <li>
              <button type='button' onClick={() => navigate("/blogs")}>
                Blogs
              </button>
            </li>
            <li>
              <button type='button' onClick={() => navigate("/events")}>
                Events
              </button>
            </li>
          </ul>
        </div>

        {/* nav profile icon  */}
        <div
          className='nav-profile-icon'
          onMouseEnter={() => setShowMenu(!showMenu)}
          onMouseLeave={() => setShowMenu(!showMenu)}
        >
          <i className={showMenu ? "fa fa-times" : "fa fa-bars"}></i>
          {!isAuth ? (
            <i className='fa fa-circle-user'></i>
          ) : (
            <img src={user.image} alt='' />
          )}
        </div>
      </nav>
      {/* toggle modal for signup form  */}
      {modal === "signup" && (
        <Signup
          onClose={() => setModal("")}
          openLogin={() => setModal("login")}
        />
      )}
      {/* toggle modal for signup form  */}
      {modal === "login" && (
        <Login
          onClose={() => setModal("")}
          openSignup={() => setModal("signup")}
        />
      )}
    </Fragment>
  );
};

export default Navbar;
