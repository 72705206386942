import React from "react";
import { Line } from "@ant-design/plots";
import { Button } from "antd";
 import { useNavigate } from "react-router-dom";

const AdminHome = () => {
 
    const navigate = useNavigate()
 
    const configVisitor = {
        data: [
            { "date": "02 jan", "visitors": 354 },
            { "date": "03 jan", "visitors": 664 },
            { "date": "04 jan", "visitors": 504 },
            { "date": "05 jan", "visitors": 754 },
            { "date": "06 jan", "visitors": 654 },
            { "date": "07 jan", "visitors": 354 },
            { "date": "08 jan", "visitors": 954 },
            { "date": "09 jan", "visitors": 354 },
            { "date": "10 jan", "visitors": 554 },
        ],
        xField: "date",
        yField: "visitors",
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

  return (
    <div style={{ padding: "10px 5%" }}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button> 5000 Visitors</Button>
        <Button onClick={() => navigate("appoinments")}>
          {" "}
          20 Appoinments{" "}
        </Button>
        <Button onClick={() => navigate("blogs")}> 70 Blogs</Button>
        <Button onClick={() => navigate("doctors")}>
          Doctors
        </Button>
      </div>{" "}
      <br />
      <big>Visitors </big> <br />
      <br />
      <Line autoFit {...configVisitor} /> <br />
      <br />
    </div>
  );
}

export default AdminHome;