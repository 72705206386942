import { useRef, useState } from "react";
import axios from "../../axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [error, setError] = useState("");
  const [visible, setVisibility] = useState(false);
  const [focused, setFocused] = useState(false);
  const navigate = useNavigate();

  const admin = useRef({
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    admin.current[inputName] = inputValue;
  };

  const loginAdmin = (e) => {
    e.preventDefault();
    axios
      .post("/admins/login", admin.current)
      .then((res) => {
        sessionStorage.setItem("admin", JSON.stringify(res.data));
        navigate("/admin");
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  };

  return (
    <>
      <h1>Admin Login</h1>
      <br />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={loginAdmin}
      >
        <div className='inputBox'>
          <input
            type='text'
            required='required'
            name='email'
            onInput={handleInput}
          />
          <span>Email</span>
          <i></i>
        </div>
        <div className='inputBox'>
          <input
            type={visible ? "text" : "password"}
            required='required'
            name='password'
            onInput={handleInput}
            onFocus={() => setFocused(true)}
          />
          <span>Password</span>
          {focused &&
            (!visible ? (
              <AiFillEye onClick={() => setVisibility(!visible)} />
            ) : (
              <AiFillEyeInvisible onClick={() => setVisibility(!visible)} />
            ))}
          <i></i>
        </div>
        <p style={{ color: "red" }}>{error}</p>
        <input type='submit' value='Login' />
      </form>
    </>
  );
};

export default AdminLogin;
