import { useState } from "react";
import ModalContext from "./modalContext";

const ModalContextProvider = ({ children }) => {
   const [open, setIsOpen] = useState(false);

  const modalContextValue = {
    open,
    setIsOpen
  };

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
