import React from "react";
import "../../CSS/AboutUs & ContactUs/About.css";
import img2 from "../../images/img2.webp";

const ThirdBanner = () =>{
  return (
    <div className="benefit">
      <div className="benefit-left">
        <h1>
          Why <span>Mindikko</span> is the best for you
        </h1>
        <p>
          Mindikko provides a team of highly trained psychiatrists dedicated to
          providing the highest quality care and support to those seeking
          emotional wellness. The expertise of psychologists, combined with
          their genuine care and empathy, makes them the key to unlocking the
          power of transformation and healing. With a deep commitment to a
          holistic approach, they offer a safe and compassionate environment
          where individuals can receive the care they deserve.
        </p>
        <p>
          Mindikko aims to offer a unique approach to patient care with
          specialized a unique AI-powered app. Mindikko utilises technology to
          enhance the patient experience.We are providing personalized treatment
          plans to manage depression symptoms and mindfulness-based therapy to
          increase overall well-being.
        </p>
        <p>
          The standout features are budget-friendly, robust privacy protection,
          a cutting-edge AI-powered solution, and a skilled team of experts.Take
          the first step towards a brighter future by booking an appointment
          with one of our experts today. Let us support you on your journey to
          emotional wellness.
        </p>
      </div>
      <div className="benefit-right">
        <img loading="lazy" src={img2} alt="" className="benefitImg" />
      </div>
    </div>
  );
}

export default ThirdBanner;
