import React from "react";
import FirstBanner from "../Components/About/FirstBanner";
import SecondBanner from "../Components/About/SecondBanner";
import ThirdBanner from "../Components/About/ThirdBanner";
import Accordion from "../Components/IndexPage/Accordion";

const About = () => {
  return (
    <div>
      <FirstBanner />
      <SecondBanner />
      <ThirdBanner />
      <Accordion />
    </div>
  );
}

export default About;

