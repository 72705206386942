import React from "react";
import TextField from "@mui/material/TextField";
import "../../CSS/Appointments/PhoneNumber.css";

const PhoneNumberInput = ({ appointment, handleAppointmentChange }) => {
  return (
    <TextField
      label='Phone Number'
      name='phoneNumber'
      value={appointment.phoneNumber}
      onChange={handleAppointmentChange}
      fullWidth
      className='phoneNumber'
      sx={{
        "& .MuiInputLabel-root": {
          zIndex: 1,
          backgroundColor: "#fff",
          color: "#fdae5c",
        },
      }}
    />
  );
};

export default PhoneNumberInput;
