import React, { useContext, useState } from "react";
import "../../CSS/Appointments/AppointmentModal.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import PhoneInput from "./PhoneInput";
import NameInput from "./NameInput";
import axios from "../../axios/index";
import { useNavigate } from "react-router-dom";
import ModalContext from "../../Context/modalContext";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AppointmentModal = ({doctors, selectedDoctor}) => {
  const { open, setIsOpen } = useContext(ModalContext);
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [appointment, setAppointment] = useState({
    patientName: "",
    phoneNumber: "",
    reason: "",
  });

  useEffect(() => {
      const { token, email } = JSON.parse(sessionStorage.getItem("user"));
      setEmail(email);
      setToken(token);
  }, [])

  const handleAppointmentChange = (e) => {
    const { name, value } = e.target;
    setAppointment((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const disabledDates = [];

  const shouldDisableDate = (date) =>
    disabledDates.some(
      (disabledDate) =>
        date["$d"].getFullYear() === disabledDate.getFullYear() &&
        date["$d"].getMonth() === disabledDate.getMonth() &&
        date["$d"].getDate() === disabledDate.getDate()
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    const newAppointment = {
      patientName: appointment.patientName,
      phoneNumber: appointment.phoneNumber,
      reason: appointment.reason,
      doctorName: selectedDoctor.name,
      time,
      date,
      email,
    };

    axios
      .post("/api/appointments", newAppointment, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        setIsOpen(false);
        navigate("/profile");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const theme = createTheme();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setIsOpen(false)}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
        className='appointment'
      >
        <Box className='modal-content'>
          <div className='appointment-title'>
            <h2>Book a Session</h2>
          </div>
          <span onClick={() => setIsOpen(false)}>
            <FaTimes />
          </span>
          <div className='book-session-dropdown'>
            <div className='book-session-dropdown-two'>
              <ThemeProvider theme={theme}>
                <FormControl
                  sx={{
                    "& .MuiInputLabel-root": {
                      zIndex: 1,
                      backgroundColor: "#fff",
                      color: "#fdae5c",
                    },
                  }}
                >
                  <InputLabel id='demo-multiple-name-label'>
                    Choose Doctor
                  </InputLabel>
                  <Select
                    labelId='demo-multiple-name-label'
                    id='demo-multiple-name'
                    name='doctor'
                    value={selectedDoctor.name}
                    input={<OutlinedInput label='Name' />}
                    MenuProps={MenuProps}
                    disabled
                  >
                    {doctors.map((doc, index) => (
                      <MenuItem key={index} value={doc.name}>
                        {doc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </div>
          </div>

          <div className='book-session-name-phone'>
            <div className='book-session-name'>
              <NameInput
                appointment={appointment}
                handleAppointmentChange={handleAppointmentChange}
              />
            </div>
            <div className='book-session-name-phone-phone'>
              <PhoneInput
                appointment={appointment}
                handleAppointmentChange={handleAppointmentChange}
              />
            </div>
          </div>

          <div className='app-appointment-date-picker'>
            <div className='app-date-picker-container'>
              <div className='app-date-picker-input'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label='Choose Date'
                      name='date'
                      onChange={(e) => setDate(e["$d"].toLocaleDateString())}
                      className='custom-date-picker'
                      shouldDisableDate={shouldDisableDate}
                      disablePast
                      sx={{
                        "& .MuiInputLabel-root": {
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#fdae5c",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className='app-time-picker-input'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      name='time'
                      label='Choose Time'
                      className='custom-date-picker time'
                      onChange={(e) =>
                        setTime(new Date(e["$d"]).toString().slice(16, 21))
                      }
                      sx={{
                        "& .MuiInputLabel-root": {
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#fdae5c",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div className='book-session-textarea'>
            <textarea
              placeholder='Type your message.'
              rows='5'
              className='apm-message-control'
              name='reason'
              value={appointment.reason}
              onChange={handleAppointmentChange}
            ></textarea>
          </div>

          <div className='book-session-button'>
            <button
              className='apm-book-session-button'
              type='button'
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AppointmentModal;
