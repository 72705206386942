import React from "react";
import TextField from "@mui/material/TextField";
import "../../CSS/Appointments/FullName.css";

const FullNameInput = ({ appointment, handleAppointmentChange }) => {
  return (
    <TextField
      label='Full Name'
      name='patientName'
      value={appointment.patientName}
      onChange={handleAppointmentChange}
      fullWidth
      className='textField'
      sx={{
        "& .MuiInputLabel-root": {
          backgroundColor: "#fff",
          color: "#fdae5c",
        },
      }}
    />
  );
};

export default FullNameInput;
