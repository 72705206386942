const PropertyDetail = [
  {
    id: "1",
    Image: "./mentalHealthIssues/anxi_sage-friedman-HS5CLnQbCOc-unsplash.jpg",
    heading: "Anxiety Disorder",
    span: "Popular Properties",
    amount: "From $20K",
    size: "1200 Sqft",
    bed: "2 Bed",
    bedImage: "./PropertyImages/double-bed.png",
    bath: "2 Bathroom",
    bathImage: "./PropertyImages/bath.png",
  },
  {
    id: "2",
    Image: "./mentalHealthIssues/rene-bernal-f0rdHx5P8sQ-unsplash.jpg",
    heading: "Child Counselling",
    span: "Popular Properties",
    amount: "From $20K",
    size: "1200 Sqft",
    bed: "2 Bed",
    bedImage: "./PropertyImages/double-bed.png",
    bath: "2 Bathroom",
    bathImage: "./PropertyImages/bath.png",
  },
  {
    id: "3",
    Image:
      "./mentalHealthIssues/Clinical_depression_yuris-alhumaydy-mSXMHkgRs8s-unsplash.jpg",
    heading: "Clinical Depression",
    span: "Popular Properties",
    amount: "From $20K",
    size: "1200 Sqft",
    bed: "2 Bed",
    bedImage: "./PropertyImages/double-bed.png",
    bath: "2 Bathroom",
    bathImage: "./PropertyImages/bath.png",
  },
  {
    id: "4",
    Image: "./mentalHealthIssues/elisa-ventur-bmJAXAz6ads-unsplash.jpg",
    heading: "Work Stress Management",
    span: "Popular Properties",
    amount: "From $20K",
    size: "1200 Sqft",
    bed: "2 Bed",
    bedImage: "./PropertyImages/double-bed.png",
    bath: "2 Bathroom",
    bathImage: "./PropertyImages/bath.png",
  },
  {
    id: "5",
    Image:
      "./mentalHealthIssues/couple_travis-grossen--CaJIE1MiA4-unsplash.jpg",
    heading: "Couple Issues",
    span: "Popular Properties",
    amount: "From $20K",
    size: "1200 Sqft",
    bed: "2 Bed",
    bedImage: "./PropertyImages/double-bed.png",
    bath: "2 Bathroom",
    bathImage: "./PropertyImages/bath.png",
  },
  {
    id: "6",
    Image: "./mentalHealthIssues/rashid-khreiss-uxDQRHi4vfo-unsplash.jpg",
    heading: "Addiction Management",
    span: "Popular Properties",
    amount: "From $20K",
    size: "1200 Sqft",
    bed: "2 Bed",
    bedImage: "./PropertyImages/double-bed.png",
    bath: "2 Bathroom",
    bathImage: "./PropertyImages/bath.png",
  },
];
export default PropertyDetail