import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { BiSearchAlt2 } from "react-icons/bi";
import images from "../../constants/image";
import "../../CSS/BlogsSphere/BlogsSphere.css";
import { useNavigate } from "react-router-dom";

const BlogsSphere = () => {

const blogs = [
  {
    id: 1,
    title: "The dangers of One sided Views",
    content: "blandit enim vel scelerisque. Aliquam ultrices diam scelerisque, hendrerit mi non, placerat neque. Proin a tortor in nisl finibus lacinia. Sed quis neque sollicitudin dui tristique auctor vitae sit amet libero. Donec in laoreet neque. Duis consectetur quis purus quis ornare. Suspendisse lobortis purus in dictum varius. Pellentesque pretium tincidunt diam sit amet pulvinar. Mauris dignissim ligula eu risus luctus laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac blandit leo. Aenean enim purus, vehicula sollicitudin libero eu, molestie pharetra mi. Curabitur ac ex magna.\n\nDonec lacinia, nisl sit amet egestas malesuada, ligula urna volutpat sem, sit amet vestibulum diam tellus in metus. Integer mauris mauris, pellentesque vitae orci id, cursus hendrerit metus. Nam sit amet pharetra nunc. Aliquam neque est, sagittis sit amet sagittis ac, dignissim eu mauris. Curabitur facilisis, mi in accumsan tempor, magna lacus auctor nunc, at consectetur neque urna sed dolor. Cras sit amet elit lobortis, placerat leo sit amet, condimentum enim. Sed rhoncus consectetur orci nec feugiat.\n\nVivamus in",
    createdAt: "2023-06-19T05:09:18.986Z",
    updatedAt: "2023-06-19T05:09:18.986Z",
  },
  {
    id: 2,
    title: "The dangers of One sided Views",
    content: "blandit enim vel scelerisque. Aliquam ultrices diam scelerisque, hendrerit mi non, placerat neque. Proin a tortor in nisl finibus lacinia. Sed quis neque sollicitudin dui tristique auctor vitae sit amet libero. Donec in laoreet neque. Duis consectetur quis purus quis ornare. Suspendisse lobortis purus in dictum varius. Pellentesque pretium tincidunt diam sit amet pulvinar. Mauris dignissim ligula eu risus luctus laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac blandit leo. Aenean enim purus, vehicula sollicitudin libero eu, molestie pharetra mi. Curabitur ac ex magna.\n\nDonec lacinia, nisl sit amet egestas malesuada, ligula urna volutpat sem, sit amet vestibulum diam tellus in metus. Integer mauris mauris, pellentesque vitae orci id, cursus hendrerit metus. Nam sit amet pharetra nunc. Aliquam neque est, sagittis sit amet sagittis ac, dignissim eu mauris. Curabitur facilisis, mi in accumsan tempor, magna lacus auctor nunc, at consectetur neque urna sed dolor. Cras sit amet elit lobortis, placerat leo sit amet, condimentum enim. Sed rhoncus consectetur orci nec feugiat.\n\nVivamus in",
    createdAt: "2023-06-19T05:09:18.986Z",
    updatedAt: "2023-06-19T05:09:18.986Z",

  },
  {
    id: 3,
    title: "The dangers of One sided Views",
    content: "blandit enim vel scelerisque. Aliquam ultrices diam scelerisque, hendrerit mi non, placerat neque. Proin a tortor in nisl finibus lacinia. Sed quis neque sollicitudin dui tristique auctor vitae sit amet libero. Donec in laoreet neque. Duis consectetur quis purus quis ornare. Suspendisse lobortis purus in dictum varius. Pellentesque pretium tincidunt diam sit amet pulvinar. Mauris dignissim ligula eu risus luctus laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac blandit leo. Aenean enim purus, vehicula sollicitudin libero eu, molestie pharetra mi. Curabitur ac ex magna.\n\nDonec lacinia, nisl sit amet egestas malesuada, ligula urna volutpat sem, sit amet vestibulum diam tellus in metus. Integer mauris mauris, pellentesque vitae orci id, cursus hendrerit metus. Nam sit amet pharetra nunc. Aliquam neque est, sagittis sit amet sagittis ac, dignissim eu mauris. Curabitur facilisis, mi in accumsan tempor, magna lacus auctor nunc, at consectetur neque urna sed dolor. Cras sit amet elit lobortis, placerat leo sit amet, condimentum enim. Sed rhoncus consectetur orci nec feugiat.\n\nVivamus in",
    createdAt: "2023-06-19T05:09:18.986Z",
    updatedAt: "2023-06-19T05:09:18.986Z",

  },
  {
    id: 4,
    title: "The dangers of One sided Views",
    content: "blandit enim vel scelerisque. Aliquam ultrices diam scelerisque, hendrerit mi non, placerat neque. Proin a tortor in nisl finibus lacinia. Sed quis neque sollicitudin dui tristique auctor vitae sit amet libero. Donec in laoreet neque. Duis consectetur quis purus quis ornare. Suspendisse lobortis purus in dictum varius. Pellentesque pretium tincidunt diam sit amet pulvinar. Mauris dignissim ligula eu risus luctus laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac blandit leo. Aenean enim purus, vehicula sollicitudin libero eu, molestie pharetra mi. Curabitur ac ex magna.\n\nDonec lacinia, nisl sit amet egestas malesuada, ligula urna volutpat sem, sit amet vestibulum diam tellus in metus. Integer mauris mauris, pellentesque vitae orci id, cursus hendrerit metus. Nam sit amet pharetra nunc. Aliquam neque est, sagittis sit amet sagittis ac, dignissim eu mauris. Curabitur facilisis, mi in accumsan tempor, magna lacus auctor nunc, at consectetur neque urna sed dolor. Cras sit amet elit lobortis, placerat leo sit amet, condimentum enim. Sed rhoncus consectetur orci nec feugiat.\n\nVivamus in",
    createdAt: "2023-06-19T05:09:18.986Z",
    updatedAt: "2023-06-19T05:09:18.986Z",

  },


]


  // const [blogs, setBlogs] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   axios
  //     .get("/api/blogs")
  //     .then((res) => {
  //       setBlogs(res.data);
  //       setFilteredBlogs(res.data);
  //     })
  //     .catch(() => {
  //       setBlogs([]);
  //       setFilteredBlogs([]);
  //     });
  // }, []);

  useEffect(() => {
    let filtered = blogs;
    if (searchInput.trim() !== "") {
      filtered = filtered.filter((blog) =>
        blog.title.toLowerCase().includes(searchInput.toLowerCase())
      );
    }
    setFilteredBlogs(filtered);
  }, [searchInput, blogs]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className='blogs-container'>
      <div className='nav-bg'></div>
      <div className='blogsSphere'>
        <div className='blogs-heading'>
          <div className='blogs-body-content'>
            {filteredBlogs.slice(0, 4).map((blog) => (
              <div className='blogs-card' key={blog._id}>
                <div className='blogs-card-image'>
                  <img src={images.blog1} alt='blog' />
                </div>
                <div className='blogs-card-content'>
                  <h3 className='blogs-card-title'>{blog.title}</h3>
                  <div className='blog-card-text'>
                    <p>{blog.content}</p>
                  </div>
                  <button
                    type='button'
                    onClick={() => navigate(`/blogs/${blog._id}`)}
                    className='blogs-card-button'
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='blogs_header-one'>
            <div className='blogs-content'>
              <div className='blogs-search-input'>
                <input
                  type='text'
                  placeholder='Search Blogs'
                  value={searchInput}
                  onChange={handleSearchChange}
                />
                <BiSearchAlt2 className='search-icon' />
              </div>
            </div>
            <div className='recent-blogs'>
              <div className='recent-blogs-heading'>
                <h3>Recent Blogs</h3>
              </div>
              <div className='recent-blogs-list'>
                <ul>
                  {blogs.slice(0, 8).map((blog) => (
                    <li key={blog._id}>
                      <a href={`blogs/${blog._id}`}>{blog.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogsSphere;
