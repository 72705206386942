import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';
import image3 from '../assets/image3.jpg';
import image4 from '../assets/image4.png';
import image5 from '../assets/image5.png';
import image6 from '../assets/image6.jpg';
import image7 from '../assets/image7.jpg';
import profile1 from '../assets/profile1.png';
import profile2 from '../assets/profile2.png';
import profile3 from '../assets/profile3.png';
import profile4 from '../assets/profile4.png';
import profile5 from '../assets/profile5.png';
import profile6 from '../assets/profile6.png';
import profile9 from '../assets/profile9.jpg';
import user2 from '../assets/user2.png';
import user3 from '../assets/user3.png';
import user4 from '../assets/user4.png';
import user5 from '../assets/user5.png';
import user6 from '../assets/user6.png';
import blog1 from '../assets/blog1.png';
import blog2 from '../assets/blog2.png';
import blog3 from '../assets/blog3.png';
import blog4 from '../assets/blog4.png';
import blog5 from '../assets/blog5.png';
import insta1 from '../assets/insta1.png';
import insta2 from '../assets/insta2.png';
import insta3 from '../assets/insta3.png';
import insta4 from '../assets/insta4.png';





const images = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    profile1,
    profile2,
    profile3,
    profile4,
    profile5,
    profile6,
    profile9,
    user2,
    user3,
    user4,
    user5,
    user6,
    blog1,
    blog2,
    blog3,
    blog4,
    blog5,
    insta1,
    insta2,
    insta3,
    insta4,
}

export default images;
