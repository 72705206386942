import React, { useEffect, useState } from "react";
import axios from "../../axios/index";
import { Modal, Button, Form, Input, Upload, Image, Table } from "antd";
import { PlusOutlined, UploadOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";

const DoctorForm = () => {
  const [name, setName] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [picture, setPicture] = useState(null);
  const [bio, setBio] = useState("");

  const { token } = JSON.parse(sessionStorage.getItem("admin"));

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [editingPost, setEditingPost] = useState(null);

  useEffect(() => {
    axios
      .get("/api/doctors", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        setData([]);
      });
  }, [token]);

  const handleModalCancel = () => {
    setShowModal(false);
    setEditingPost(null);
    resetForm();
  };

  const handleModalPost = () => {
    if (name.trim() === "" || specialization.trim() === "") {
      handleModalCancel();
      return;
    }
    const newDoctor = {
      name,
      specialization,
      phoneNumber,
      email,
      address,
      imageUrl: picture,
      bio,
    };

    if (editingPost) {
      axios
        .put(`/api/doctors/${editingPost._id}`, newDoctor, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const updatedData = data.map((doctor) =>
            doctor._id === editingPost._id ? res.data : doctor
          );
          setData(updatedData);
          handleModalCancel();
        })
        .catch(() => {
          handleModalCancel();
        });
    } else {
      axios
        .post("/api/doctors", newDoctor, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setData([...data, res.data]);
          handleModalCancel();
        })
        .catch(() => {
          handleModalCancel();
        });
    }
  };

  const handleEdit = (record) => {
    setEditingPost(record);
    setName(record.name);
    setSpecialization(record.specialization);
    setPhoneNumber(record.phoneNumber);
    setEmail(record.email);
    setAddress(record.address);
    setPicture(record.picture);
    setBio(record.bio);
    setShowModal(true);
  };

  const handleDelete = (record) => {
    axios
      .delete(`/api/doctors/${record._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        const filteredData = data.filter((doctor) => doctor._id !== record._id);
        setData(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePictureChange = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        setPicture(`data:image/jpeg;base64,${base64String}`);
      };

      reader.readAsDataURL(file);
    }
  };

  const resetForm = () => {
    setName("");
    setSpecialization("");
    setPhoneNumber("");
    setEmail("");
    setAddress("");
    setPicture(null);
    setBio("");
  };

  const columns = [
    {
      title: "#",
      key: "serial",
      render: (text, record, index) => <span>{index + 1}</span>,
    },

    {
      title: "Picture",
      key: "picture",
      render: (text, record) => (
        <div style={{ borderRadius: "50%", overflow: "hidden", width: 50, height: 50 }}>
          <Image src={record.imageUrl} alt="Doctor" width={50} height={50} />
        </div>
      ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type="link"
            style={{ fontSize: "18px" }}
            onClick={() => handleEdit(record)}
          >
            <EditFilled />
          </Button>
          <Button
            type="link"
            style={{ fontSize: "18px" }}
            danger
            onClick={() => handleDelete(record)}
          >
            <DeleteFilled />
          </Button>
        </span>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Button
          type="primary"
          style={{ background: "#fdae5c", fontWeight: "700", marginBottom: "10px" }}
          onClick={() => setShowModal(true)}
        >
          <PlusOutlined /> Add Doctor
        </Button>
      </div>
      <Table columns={columns} dataSource={data} rowKey="_id" />
      <Modal
        title={editingPost ? "Edit Doctor" : "Add Doctor"}
        visible={showModal}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key="post" type="primary" onClick={handleModalPost}>
            {editingPost ? "Save" : "Add"}
          </Button>,
        ]}
      >
        <Form.Item label="Picture">
          <Upload
            accept=".jpeg, .png, .jpg"
            onChange={handlePictureChange}
            beforeUpload={() => false}
          >
            <Input
              readOnly
              value={picture ? picture.name : ""}
              suffix={<UploadOutlined />}

            />

          </Upload >
          {picture && (
            <Image
              src={picture}
              alt="Preview"
              width={100}
              height={100}
              style={{ marginTop: "10px" }}
            />
          )}
        </Form.Item>
        <Form.Item label="Name">
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Item>
        <Form.Item label="Specialization">
          <Input
            type="text"
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
            required
          />
        </Form.Item>
        <Form.Item label="Phone Number">
          <Input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </Form.Item>
        <Form.Item label="Email">
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Item>
        <Form.Item label="Address">
          <Input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </Form.Item>
        <Form.Item label="Bio">
          <Input.TextArea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            required
          />
        </Form.Item>
      </Modal>
    </>
  );
};

export default DoctorForm;