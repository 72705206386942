import { Button, Form, Input, Modal, Radio, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "../../axios";


const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const [filter, setFilter] = useState("New");
  const { token } = JSON.parse(sessionStorage.getItem("admin"));
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [editingAdmin, setEditingAdmin] = useState(false);
  const [admin, setAdmin] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    axios
      .get("/admins", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAdmins(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancel = () => {
    setOpen(false);
    setAdmin({
      username: "",
      email: "",
      password: "",
    });
    setPasswordConfirmation("");
    setError("");
    setMessage("");
    setEditingAdmin(false);
  };

  const openAdminEditingMode = (record) => {
    setOpen(true);
    setEditingAdmin(true);
    setAdmin({
      _id: record._id,
      username: record.username,
      email: record.email,
    });
  };

  const editAdmin = () => {
    setLoading(true);
    if (admin.username === "" || admin.email === "") {
      setError("Please enter a valid username and email");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    const editedAdmin = {
      username: admin.username,
      email: admin.email,
    };

    axios
      .put(`/admins/edit-admin/${admin._id}`, editedAdmin, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAdmins((prevAdmins) => {
          const index = prevAdmins.findIndex(
            (admin) => admin._id === res.data._id
          );
          prevAdmins[index] = res.data;
          return prevAdmins;
        });
        setLoading(false);
        setMessage(res.data.message);
        setEditingAdmin(false);
        setOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  const signUpAdmin = async (e) => {
    e.preventDefault();
    if (
      admin.username === "" ||
      admin.email === "" ||
      admin.password === "" ||
      passwordConfirmation === ""
    ) {
      setError("Please fill in all fields");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    setLoading(true);
    if (admin.password !== passwordConfirmation) {
      setLoading(false);
      setError("Passwords do not match");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    axios
      .post("/admins/signup", admin)
      .then((res) => {
        setLoading(false);
        setMessage(res.data.message);

        setTimeout(() => {
          handleCancel();
          setOpen(false);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const searchAdmins = (value) => {
    if (value.trim() === "") {
      return;
    }
    setQuery(value);
  };

  const filteredAdmins = admins.filter(
    (admin) =>
      admin.username.toLowerCase().includes(query.toLowerCase()) ||
      admin.email.toLowerCase().includes(query.toLowerCase())
  );
  const handleDelete = (record) => {
    axios
      .delete(`/admins/delete-admin/${record._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAdmins((prevAdmins) => {
          const filteredAdmins = prevAdmins.filter(
            (admin) => admin._id !== res.data._id
          );
          return filteredAdmins;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <Button size='small' onClick={() => openAdminEditingMode(record)}>
            <EditOutlined />
          </Button>
          &ensp;
          <Button
            size='small'
            type='primary'
            danger
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Button
        style={{
          backgroundColor: "#fdae5c",
          color: "white",
          fontWeight: "bold",
          borderRadius: "5px",
          padding: "3px 10px",
          marginBottom: "20px",
        }}
        onClick={() => setOpen(true)}
      >
        + Add admin
      </Button>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Radio.Group
            options={["All", "Active", "Blocked"]}
            optionType='button'
            buttonStyle='solid'
            defaultValue='All'
            onChange={handleFilterChange}
          />
          <Input
            style={{ maxWidth: "300px" }}
            placeholder='Search by Name or Email'
            onChange={(e) => searchAdmins(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          dataSource={filteredAdmins.map((item, index) => ({
            ...item,
            key: index.toString(),
          }))}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.content}</p>
            ),
            expandIconColumnIndex: -1,
          }}
        />
      </div>
      <Modal
        style={{
          fontStyle: "bold",
          borderRadius: "20px",
          fontWeight: 700,
          color: "white",
          bottom: 300,
        }}
        closable={false}
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            key='cancel'
            style={{ zIndex: 1000 }}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
          <Button
            key='post'
            style={{ zIndex: 1000 }}
            type='primary'
            onClick={editingAdmin ? editAdmin : signUpAdmin}
          >
            {loading && !editingAdmin
              ? "Verifying..."
              : loading && editingAdmin
              ? "Updating..."
              : !loading && editingAdmin
              ? "Update admin"
              : "Add admin"}
          </Button>,
        ]}
        contentStyle={{ backgroundColor: "transparent" }}
      >
        <Form.Item label='Username'>
          <Input
            type='text'
            id='username'
            name='username'
            value={admin.username}
            onChange={(e) => handleInput(e)}
            required
          />
        </Form.Item>
        <Form.Item label='Email'>
          <Input
            type='email'
            id='email'
            name='email'
            value={admin.email}
            onChange={(e) => handleInput(e)}
            required
          />
        </Form.Item>
        {!editingAdmin && (
          <>
            <Form.Item label='Password'>
              <Input
                type='password'
                id='password'
                name='password'
                value={admin.password}
                onChange={(e) => handleInput(e)}
                required
                editable={!editingAdmin}
              />
            </Form.Item>
            <Form.Item label='Password confirmation'>
              <Input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                required
                editable={!editingAdmin}
              />
            </Form.Item>
          </>
        )}
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {message && (
          <p style={{ color: "green", textAlign: "center" }}>{message}</p>
        )}
      </Modal>
    </>
  );
};

export default Admin;
