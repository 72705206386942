import { useState } from "react";
import LoginModalContext from "./LoginModalContext";

const LoginModalContextProvider = ({ children }) => {
    const [modal, setModal] = useState("");

  const modalContextValue = {
    modal,
    setModal,
  };

  return (
    <LoginModalContext.Provider value={modalContextValue}>
      {children}
    </LoginModalContext.Provider>
  );
};

export default LoginModalContextProvider;
