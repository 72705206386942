import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import { useEffect } from "react";
import "../../CSS/BlogsSphere/Blog.css";
import images from "../../constants/image";

const Blog = () => {
  // const [blog, setBlog] = useState([]);
  // const { id } = useParams();

  const blog =   {
    id: 1,
    title: "The dangers of One sided Views",
    content: "blandit enim vel scelerisque. Aliquam ultrices diam scelerisque, hendrerit mi non, placerat neque. Proin a tortor in nisl finibus lacinia. Sed quis neque sollicitudin dui tristique auctor vitae sit amet libero. Donec in laoreet neque. Duis consectetur quis purus quis ornare. Suspendisse lobortis purus in dictum varius. Pellentesque pretium tincidunt diam sit amet pulvinar. Mauris dignissim ligula eu risus luctus laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac blandit leo. Aenean enim purus, vehicula sollicitudin libero eu, molestie pharetra mi. Curabitur ac ex magna.\n\nDonec lacinia, nisl sit amet egestas malesuada, ligula urna volutpat sem, sit amet vestibulum diam tellus in metus. Integer mauris mauris, pellentesque vitae orci id, cursus hendrerit metus. Nam sit amet pharetra nunc. Aliquam neque est, sagittis sit amet sagittis ac, dignissim eu mauris. Curabitur facilisis, mi in accumsan tempor, magna lacus auctor nunc, at consectetur neque urna sed dolor. Cras sit amet elit lobortis, placerat leo sit amet, condimentum enim. Sed rhoncus consectetur orci nec feugiat.\n\nVivamus in",
    createdAt: "2023-06-19T05:09:18.986Z",
    updatedAt: "2023-06-19T05:09:18.986Z",
  }

  // useEffect(() => {
  //   axios
  //     .get(`/api/blogs/${id}`)
  //     .then((res) => {
  //       console.log(res);
  //       setBlog(res.data);
  //     })
  //     .catch(() => {
  //       setBlog([]);
  //     });
  // }, [id]);

  return (
    <div className='blog-container'>
      <div className='nav-bg' />
      <div class='blog'>
      <i>
          Written by {blog.author} on {new Date(blog.createdAt).toUTCString()}/ <a href='#comments'>3 Comments</a>
                    
      </i>
        <h1>{blog.title}</h1>
        {blog.content &&
          blog.content.split("\n").map((para, index) => {
            return <p key={index}>{para}</p>;
          })}
        <div class='comments' id='comments'>
          <h1>Comments</h1>
          <div class="comment-section">
            <div class="comment-input">
              <img src={images.user6}  alt='blog' />
              <textarea className="comment-textarea" rows="4" cols="50" placeholder="Write a comment..."></textarea>
              </div>
              <button className="post-button">Post</button>
            </div>


          </div>
          <div className="comment">
            <div className="user-info">
              <img src={images.user6} alt='blog' className="profile-image-comment" />
              <span className="user-name">Tom Cruise</span>
              <span className="emoji">😊</span>
              <span className="time">1Hr</span>
            </div>
            <p className="comment-text">
              I combine my medical expertise with my passion for effective communication. I strive to present information in a clear, engaging manner, ensuring that readers can easily grasp the key points and apply them to their own lives.
            </p>
            <div className="comment-actions">
              <a href=""><span className="reply">reply</span></a>
              <span className="likes">120 likes</span>
              <span className="like-icon">👍</span>
              <span className="dislike-icon">👎</span>
            </div>
          </div>



      </div>


    </div>
  );
};

export default Blog;
