import {
  CaretDownOutlined,
  FileAddOutlined,
  GlobalOutlined,
  HomeOutlined,
  LineChartOutlined,
  ProfileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, ConfigProvider, Layout, Menu, theme } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../CSS/Admin/index.css";
import Login from "./login";
import axios from "../axios";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Home", "/admin", <HomeOutlined />),
  getItem("Profile", "profile", <ProfileOutlined />),
  getItem("Appointments", "appointments", <FileAddOutlined />),
  getItem("Blogs", "blogs", <GlobalOutlined />),
  getItem("Users", "users", <TeamOutlined />, [
    getItem("Admins", "admin"),
    getItem("Customers", "customer"),
    getItem("Doctors", "doctors"),
  ]),
  getItem("Careers", "careers", <LineChartOutlined />, [
    getItem("New", "new-career"),
    getItem("Resolved", "resolved-career"),
    getItem("Rejected", "rejected-career"),
  ]),
];

const AdminOutlet = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const admin = sessionStorage.getItem("admin") || null;

  useEffect(() => {
    if (admin) {
      const { token } = JSON.parse(admin);
      axios.get("/admins/verify-admin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsAuthorized(true);
      })
      .catch((err) => {
        setIsAuthorized(false);
        sessionStorage.removeItem("admin");
      }
      );
    } else {
      setIsAuthorized(false);
    }
  }, [admin]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    sessionStorage.removeItem("admin");
    navigate("/admin");
  };
  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#fdae5c",
        },
      }}
    >
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          {!collapsed ? (
            <div className='logo'>
              Mindikko<font color='#fdae5c'>.</font>
            </div>
          ) : (
            <div className='logo'>
              M<font color='#fdae5c'>.</font>
            </div>
          )}

          <Menu
            theme='dark'
            defaultSelectedKeys='/'
            mode='inline'
            onClick={(e) => navigate(e.key)}
            items={items}
          />
        </Sider>
        <Layout className='site-layout'>
          <Header
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "25px",
              alignItems: "center",
              background: colorBgContainer,
            }}
          >
            <Badge>
              <Avatar size={30} icon={<UserOutlined />} shape='circle' /> &nbsp;
              {admin ? JSON.parse(admin).email : ""}
              <div class='dropdown'>
                <button class='dropdown-toggle'>
                  <CaretDownOutlined />
                </button>
                <button className='dropdown-menu' onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </Badge>
          </Header>
          <Content>
            <div
              style={{
                padding: "0 5%",
                minHeight: 400,
                background: colorBgContainer,
              }}
            >
              {/* condition for check admin auth  */}
              {isAuthorized ? <Outlet /> : <Login />}
            </div>
          </Content>

          <Footer
            style={{
              textAlign: "center",
            }}
          >
            <p>
              &copy;
              <a href='https://www.mindikko.com/' style={{ font: "Verdana #000" }}>
                Mindikko.
              </a>
              <span>{new Date().getFullYear()} All Rights Reserved.</span>
            </p>
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AdminOutlet;
