import React from 'react';
import AvailableDates from '../../Components/Appointments/AvailableDates'
import Search from '../../Components/Appointments/Search'
import "../../CSS/Appointments/DateSearch.css";


const DateSearch = () => {
  return (
    <div className='app-date-search-container'>
        <div className='app-DateSearch-header'>
            <h1>Find a Doctor</h1>
            <p>Search for a doctor by name or Available Dates .</p> 
        </div>
        <div className='app-DateSearch-content'>
            <div>
                <AvailableDates />
            </div>
            <div>
                <Search />
            </div>
        </div>
        
    </div>
  )
}

export default DateSearch