import React from "react";
import "../../CSS/Appointments/HomePage.css";
import images from "../../constants/image";
import DateSearch from "../../Components/Appointments/DateSearch";
import Doctors from "../../Components/Appointments/Doctors";
import PatientTestimonial from "../../Components/Appointments/PatientTestimonial";


const HomePage = () => {
  return (
    <>
      <div className='app-homepage-content'>
        <div className='nav-bg' />
        <div className='app-homepage-container'>
          <div className='app-homepage-header'>
            <div className='app-homepage-title'>
              <p>
                Thank you for choosing Mindikko for your healthcare needs. Our
                mission is to provide personalized, patient-centered care that
                puts you first. Whether you're looking for a virtual
                consultation or need ongoing care, our team of dedicated doctors
                and support staff is here to support you every step of the way.
              </p>
            </div>
            <div className='app-homepage-text'>
              <p>
                Not Sure where to start? <a href='#our-doctors'>Click here</a>{" "}
                to check out a list of our doctors and services.
              </p>
            </div>

            <div className='app-homepage-button'>
              <button
                className='app-homepage-btn'
                onClick={() => (window.location.href = "#our-doctors")}
              >
                Get Matched
              </button>
            </div>
          </div>
          <div className='app-homepage-image'>
            <img src={images.image2} alt='psychiatrist' />
          </div>
        </div>

        <div className='app-homepage-datesearch'>
          <DateSearch />
        </div>

        <div id='our-doctors' className='app-homepage-doctors'>
          <Doctors />
        </div>

        <div className='app-patient-testimonial'>
          <PatientTestimonial />
        </div>
      </div>
    </>
  );
};

export default HomePage;
