import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import "../../CSS/Appointments/Search.css";


const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="search-bar">
      <div className="search-input">
        <input className='input'
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleInputChange}
        />
        <FaSearch className="search-icon" />
      </div>
    </div>
  );
};

export default Search;
