import React from "react";
import "../../CSS/AboutUs & ContactUs/About.css";
import img from "../../images/img.webp";

const FirstBanner = () => {
  return (
    <div className="about">
      <div className="about-left">
        <img loading="lazy" src={img} alt="" className="aboutimg" />
      </div>
      <div className="about-right">
        <div className="about-right-middle">
          <h1>About Us</h1>
          <p>
            Mindikko offers a comprehensive range of emotional wellness
            services, including personalized treatment plans and
            mindfulness-based therapy, to individuals around the world who are
            struggling with depression. Our team of experts is here to support
            you, providing a warm and compassionate ear to listen and a safe
            space to share your struggles.
          </p>
        </div>
        <div className="about-right-end">
          <a href="/">Home</a> &nbsp;&nbsp;&nbsp;» &nbsp;&nbsp;
          <span>About Us</span>
        </div>
      </div>
    </div>
  );
}

export default FirstBanner;
