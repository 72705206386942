import { useState } from "react";
import DoctorContext from "./doctorContext";

const DoctorContextProvider = ({ children }) => {
  const [doctor, setDoctor] = useState({});

  const doctorContextValue = {
    doctor,
    setDoctor,
  };

  return (
    <DoctorContext.Provider value={doctorContextValue}>
      {children}
    </DoctorContext.Provider>
  );
};

export default DoctorContextProvider ;
