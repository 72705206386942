import React, { Fragment, useState } from "react";
import "../CSS/AboutUs & ContactUs/Contact.css";
import {BsFillTelephoneOutboundFill} from 'react-icons/bs';
import {MdOutlineMarkEmailRead} from 'react-icons/md';
import {GoLocation} from 'react-icons/go';
import TextField from "@mui/material/TextField";

function Contact() {

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    console.log(formData);
  };

  return (
    <Fragment>
      <div className="contact-banner"> </div>
 
      <section className="contact-section">

        <div className="contact-container">
          <div className="contact-title">
            <h1>Contact Us</h1>
          </div>

          <div className="contact-Paged">
          <div className="app-contact">
                <div className="contact-number">
                  <div className="contact-icon">
                    <BsFillTelephoneOutboundFill className="telephone-icon"/>
                  </div>

                  <div className="contact-text">
                    <h3>Phone Number</h3>
                    <p>123-456-7890</p>
                  </div>

                </div>
                <div className="contact-number">
                  <div className="contact-icon">
                    <MdOutlineMarkEmailRead className="telephone-icon"/>
                  </div>

                  <div className="contact-text">
                    <h3>Email Address</h3>
                    <p>mindikko@gmail.com</p>
                  </div>

                </div>

                <div className="contact-number">
                  <div className="contact-icon">
                    <BsFillTelephoneOutboundFill className="telephone-icon"/>
                  </div>

                  <div className="contact-text">
                    <h3>Fax Number</h3>
                    <p>123-456-7890</p>
                  </div>

                </div>

                <div className="contact-number">
                  <div className="contact-icon">
                    <GoLocation className="telephone-icon"/>
                  </div>

                  <div className="contact-text">
                    <h3>Location</h3>
                    <p>New Delhi India</p>
                  </div>

                </div>

            </div>

            <div className="contact-form">
              <h3>Send Message</h3>
              <p>Thank you for reaching out to us! We appreciate your intrest and value your feedback. We will get back to you as soon as possible </p>

              <div className="contact-name">
                <div className="contact-name-content">
                  <div className="contact-name-input">
                    <TextField
                      label='FullName'
                      name='FullName'
                      value= {formData.username}
                      onChange={handleChange}
                      fullWidth
                      className='fullname'
                    />
                
                  </div>
                  <div className="contact-email-input">

                    <TextField
                      label='Email'
                      name='email'
                      value= {formData.email}
                      onChange={handleChange}
                      fullWidth
                      className='email'
                    />

                  </div>

                </div>

                <div className="contact-name-subject">
                  <div className="contact-subject-number-input">
                    <TextField
                      label='Phone Number'
                      name='phoneNumber'
                      value= {formData.phoneNumber}
                      fullWidth
                      onChange={handleChange}
                      className='phone-number'
                    />
                    </div>

                  <div className="contact-subject-input">
                    <TextField

                      label='Subject'
                      name='subject'
                      value= {formData.subject}
                      fullWidth
                      onChange={handleChange}
                      className='subject'

                    />  

                    </div>

                </div>

                <div className="contact-message-input">
                  <textarea
                    placeholder='Type your message.'
                    rows='5'
                    className='apm-message-control'
                    name='Message'
                    onChange={handleChange}
                  >
                  </textarea>
                </div>

                <div className="contact-submit">
                  <button className="contact-submit-button" onClick={handleSubmit}>Submit</button>
                 </div>

              </div>

            </div>


            

          </div>

            


        </div>
        
      </section>
    </Fragment>
  );
}

export default Contact;
