import { Fragment } from "react"
import Home from "../Components/IndexPage/Home"
import Property from "../Components/IndexPage/property"
import Services from "../Components/IndexPage/Services"
import Work from "../Components/IndexPage/Work"
import Counter from "../Components/IndexPage/Counter"
import Agents from "../Components/IndexPage/Agents"



const IndexPage = () => {

  return (
  <Fragment>
    <Home />
    <Property/>
    <Services />
    <Work/>
    <Counter/>
    <Agents />
  </Fragment>
  )
}

export default IndexPage