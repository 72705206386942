import React, { Fragment, useEffect, useState } from "react";
import AgentsDetail from "../../Detail/AgentsDetails";
import "../../CSS/IndexPage/Agents.css";
import axios from "../../axios";

const Agents = () => {
const [doctors, setDoctors] = useState([]);

useEffect(() => {
  axios.get("/api/doctors").then((res) => {
    setDoctors(res.data);
  });
}, []);

const filteredDoctors = doctors.slice(0, 4);

  return (
    <Fragment>
      <section className='agents'>
        <div className='agents-heading'>
          <span>Practitioners</span>
          <h2> Our Doctors </h2>
        </div>

        <div className='agents-row'>
          {filteredDoctors.map((doc) => {
            return (
              <div className='agents-column' key={Math.random()}>
                <div className='agents-card'>
                  <div className='agent-thumb'>
                    <img src={doc.imageUrl} alt={doc.name} />
                    <div className='social-link'>
                      <ul>
                        <li>
                          <a href='#1'>
                            <i className='fa-brands fa-facebook-f'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#1'>
                            <i className='fa-brands fa-twitter'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#1'>
                            <i className='fa-brands fa-instagram'></i>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className='agent-info'>
                    <h3> {doc.name}</h3>
                    <p>{doc.specialization}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Fragment>
  );
};

export default Agents;
