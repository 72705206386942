import React, { useContext, useEffect, useState } from "react";
import "../../CSS/Appointments/Doctors.css";
import { Pagination, EffectCoverflow } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import { useNavigate } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DoctorContext from "../../Context/doctorContext";
import axios from "../../axios";
import ModalContext from "../../Context/modalContext";
import AppointmentModal from "./AppointmentModal";

const Doctors = () => {
  const { setIsOpen } = useContext(ModalContext);
  const [doctors, setDoctors] = useState([]);

  const fetchDoctors = async () => {
    try {
      const response = await axios.get("/api/doctors");
      setDoctors(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  const navigate = useNavigate();
  const { doctor, setDoctor } = useContext(DoctorContext);

  return (
    <div className='app-homepage-doctors-header'>
      <div className='app-homepage-doctors-header-content'>
        <h1>Our Doctors</h1>
      </div>
      {!doctors.length && (
        <Swiper
          effect={"coverflow"}
          slidesPerView={"auto"}
          centeredSlides={true}
          loop={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@2.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@2.50": {
              slidesPerView: 6,
              spaceBetween: 70,
            },
          }}
          pagination={{ clickable: true }}
          modules={[EffectCoverflow, Pagination]}
          className='swiper-container'
        >
          <SwiperSlide className='doctors'>
            <div className='carded'>
              <div className='doctors-image'>
                <img className='image' src={null} alt='' />
              </div>
              <div className='doctors-text'>
              </div>
              <div className='doctors-buttons'>
                <button
                  className='profile-button'
                  style={{ color: "#fff", pointerEvents: "none" }}
                >
                  View Profile
                </button>
                <button
                  className='book-button'
                  style={{ color: "#fff", pointerEvents: "none" }}
                >
                  Book Session
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      )}
      <AppointmentModal doctors={[doctor]} selectedDoctor={doctor} />
      <div className='app-homepage-doctors-header-swiper'>
        <Swiper
          effect={"coverflow"}
          slidesPerView={"auto"}
          centeredSlides={true}
          loop={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@2.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@2.50": {
              slidesPerView: 6,
              spaceBetween: 70,
            },
          }}
          pagination={{ clickable: true }}
          modules={[EffectCoverflow, Pagination]}
          className='swiper-container'
        >
          {doctors.map((doctor, index) => {
            return (
              <SwiperSlide key={index} className='doctors'>
                <div className='carded'>
                  <div className='doctors-image'>
                    <img
                      className='image'
                      src={doctor.imageUrl}
                      alt={doctor.name}
                    />
                  </div>
                  <div className='doctors-text'>
                    <h3>{doctor.name}</h3>
                    <p style={{fontStyle: 'italic'}}>{doctor.specialization}</p>
                  </div>
                  <div className='doctors-buttons'>
                    <button
                      className='profile-button'
                      onClick={() => {
                        navigate(`/appointment/doctor-profile/${doctor._id}`);
                      }}
                    >
                      View Profile
                    </button>
                    <button
                      className='book-button'
                      onClick={() => {
                        setDoctor(doctor);
                        setTimeout(() => {
                          setIsOpen(true);
                        }, 1);
                      }}
                    >
                      Book Session
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Doctors;
