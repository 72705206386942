import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import IndexPage from "./pages/IndexPage";
import Profile from "./pages/Profile";
import Appointment from "./pages/Appointment";
import EventTopbar from "./Components/Community/EventTopbar";
import DoctorsProfile from "./Components/Appointments/DoctorsProfile";

// this importation for admin all routes
import AdminOutlet from "./admin/AdminOutlet";
import AdminHome from "./admin/home/index";
import Appointments from "./admin/appointments";
import Blogs from "./admin/blogs";
import Admin from "./admin/admin";
import Customer from "./admin/customer";
import AdminProfile from "./admin/profile";
import { AuthContextProvider } from "./Context/authContextProvider";
import BlogsSphere from "./Components/BlogSphere/BlogsSphere";
import DoctorContextProvider from "./Context/doctorContextProvider";
import Doctors from "./admin/doctor";
import ModalContextProvider from "./Context/ModalContextProvider";
import LoginModalContextProvider from "./Context/LoginModalContextProvider";
import { useEffect } from "react";
import NavbarContextProvider from "./Context/navbarContext";
import Blog from "./Components/BlogSphere/Blog";

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AuthContextProvider>
        <LoginModalContextProvider>
          <DoctorContextProvider>
            <NavbarContextProvider>
              <ModalContextProvider>
                <Routes>
                  <Route path='/' element={<Layout />}>
                    <Route index element={<IndexPage />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/blogs' element={<BlogsSphere />} />
                    <Route path='/blogs/:id' element={<Blog />} />
                    <Route path='/appointment' element={<Appointment />} />
                    <Route
                      path='/appointment/doctor-profile/:id'
                      element={<DoctorsProfile />}
                    />
                    <Route path='/aboutus' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/events' element={<EventTopbar />} />
                  </Route>
                </Routes>
              </ModalContextProvider>
            </NavbarContextProvider>
          </DoctorContextProvider>
        </LoginModalContextProvider>
      </AuthContextProvider>
      {/* for admin parts  */}
      <Routes>
        <Route path='/admin' element={<AdminOutlet />}>
          <Route index element={<AdminHome />} />
          <Route path='appointments' element={<Appointments />} />
          <Route path='blogs' element={<Blogs />} />
          <Route path='admin' element={<Admin />} />
          <Route path='customer' element={<Customer />} />
          <Route path='doctors' element={<Doctors />} />
          <Route path='profile' element={<AdminProfile />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
