import React from "react";
import "../../CSS/CommunityPages/EventTopbar.css";
import { BiNetworkChart } from "react-icons/bi";
import { FaUsers, FaMicrophoneAlt } from "react-icons/fa";
import { TbMoodCrazyHappy } from "react-icons/tb";
import images from "../../constants/image";

const Speakers = [
  {
    id: 1,
    image: images.user2,
    name: "Dr. Asa Walter",
    title: "Psychiatrist",
  },
  {
    id: 2,
    image: images.user3,
    name: "Dr. Eric Kamau",
    title: "Psychiatrist",
  },
  {
    id: 3,
    image: images.user4,
    name: "Dr. Karen Dawnson",
    title: "Psychiatrist",
  },
  {
    id: 4,
    image: images.user5,
    name: "Dr. John Doe",
    title: "Psychiatrist",
  },
  {
    id: 5,
    image: images.user6,
    name: "Dr. Teresia Wairimu",
    title: "Psychiatrist",
  },
  {
    id: 6,
    image: images.user2,
    name: "Dr. Jane Doe",
    title: "Psychiatrist",
  },
];

const upcomingEvents = [
  {
    id: 1,
    image: images.blog1,
    title: "Mental Health Symposium",
    date: "12th June 2021",
    description:
      "A comprehensive symposium focused on various aspects of mental health, featuring renowned psychologists, psychiatrists, and experts in the field",
  },
  {
    id: 2,
    image: images.blog2,
    title: "Stress Management Workshop",
    date: "12th June 2021",
    description:
      "A comprehensive symposium focused on various aspects of mental health, featuring renowned psychologists, psychiatrists, and experts in the field",
  },
  {
    id: 3,
    image: images.blog3,
    title: "Mental Health Awareness Campaign",
    date: "12th June 2021",
    description:
      "A comprehensive symposium focused on various aspects of mental health, featuring renowned psychologists, psychiatrists, and experts in the field",
  },
  {
    id: 4,
    image: images.blog4,
    title: "How to be a good doctor",
    date: "12th June 2021",
    description:
      "A comprehensive symposium focused on various aspects of mental health, featuring renowned psychologists, psychiatrists, and experts in the field",
  },
  {
    id: 5,
    image: images.blog5,
    title: "How to be a good doctor",
    date: "12th June 2021",
    description:
      "A comprehensive symposium focused on various aspects of mental health, featuring renowned psychologists, psychiatrists, and experts in the field",
  },
  {
    id: 6,
    image: images.blog3,
    title: "How to be a good doctor",
    date: "12th June 2021",
    description:
      "A comprehensive symposium focused on various aspects of mental health, featuring renowned psychologists, psychiatrists, and experts in the field",
  },
];

const EventTopbar = () => {
  return (
    <>
      <div className='event-topbar'>
        <div className='event-box'>
          <div className='event-box-content'>
            <h3>Events</h3>
            <p className='event-text'>
              Welcome to our Doctors Programs event, where medical professionals
              gather for exceptional experiences. Our meticulously planned
              events offer networking, knowledge exchange, and professional
              development. Join us to connect, learn, and excel in the
              ever-evolving field of medicine.
            </p>
            <div className='event-box-content-time'>
              <div className='event-box-content-time-days'>
                <h4>12</h4>
                <p>Days</p>
              </div>
              <div className='event-box-content-time-hours'>
                <h4>06</h4>
                <p>Hours</p>
              </div>
              <div className='event-box-content-time-mins'>
                <h4>44</h4>
                <p>Mins</p>
              </div>
              <div className='event-box-content-time-secs'>
                <h4>31</h4>
                <p>Secs</p>
              </div>
            </div>
          </div>
        </div>
        <div className='event-box-register'>
          <h1>Register</h1>
          <p>Fill in your details below to register for the event:</p>
          <div className='input-container'>
            <input type='text' placeholder='Name' />
            <input type='tel' placeholder='Phone' />
            <input type='email' placeholder='Email' />
          </div>
          <button className='register-button'>Register Now</button>
        </div>
      </div>

      <div className='event-why-join'>
        <div className='event-why-join-content'>
          <h1>Why Join Us?</h1>
          <p>
            We are a community of medical professionals who are passionate about
            sharing knowledge and experiences. We believe in the power of
            collaboration and networking to advance the field of medicine. Our
            events are designed to help you connect with like-minded
            professionals, learn from experts, and grow your career.
          </p>
          <button className='event-why-join-button'>Buy Ticket</button>
        </div>

        <div className='why-join-card'>
          <div className='net-card'>
            <div className='card-header'>
              <h1>Networking</h1>
              <span className='card-icon'>
                <BiNetworkChart />
              </span>
            </div>
            <p>
              where medical professionals gather for exceptional experiences.
              Our meticulously planned events offer networking, knowledge
              exchange, and professional development.
            </p>
          </div>
          <div className='net-card'>
            <div className='card-header'>
              <h1>New People</h1>
              <span className='card-icon'>
                <FaUsers />
              </span>
            </div>
            <p>
              where medical professionals gather for exceptional experiences.
              Our meticulously planned events offer networking, knowledge
              exchange, and professional development.
            </p>
          </div>
          <div className='net-card'>
            <div className='card-header'>
              <h1>Great Speakers</h1>
              <span className='card-icon'>
                <FaMicrophoneAlt />
              </span>
            </div>
            <p>
              where medical professionals gather for exceptional experiences.
              Our meticulously planned events offer networking, knowledge
              exchange, and professional development.
            </p>
          </div>
          <div className='net-card'>
            <div className='card-header'>
              <h1>Have Fun</h1>
              <span className='card-icon'>
                <TbMoodCrazyHappy />
              </span>
            </div>
            <p>
              where medical professionals gather for exceptional experiences.
              Our meticulously planned events offer networking, knowledge
              exchange, and professional development.
            </p>

            <div className='event-circle'>
              <div className='half red'>
                <span className='number-event'>1</span>
              </div>
              <div className='half blue'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='event-speakers'>
        <div className='event-speakers-header'>
          <p>Listen to The </p>
          <h1>Event Speakers</h1>
        </div>
        <div className='event-speakers-content'>
          {Speakers.map((speaker) => {
            const { id, image, name, title } = speaker; 
            return (
              <div className='event-speakers-card' key={id}>
                <div className='event-speakers-card-image'>
                  <img src={image} alt={name} />
                </div>
                <div className='event-speakers-card-content'>
                  <h3>{name}</h3>
                  <p>{title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='event-upcoming'>
        <div className='event-upcoming-header'>
          <p>Upcoming Events</p>
          <h1>Our Events</h1>
        </div>
        <div className='event-upcoming-content'>
          {upcomingEvents.map((event) => {
            const { id, image, title, date, description } = event;
            return (
              <div className='event-upcoming-card' key={id}>
                <div className='event-upcoming-card-image'>
                  <img src={image} alt={title} />
                </div>
                <div className='event-upcoming-card-content'>
                  <h3>{title}</h3>
                  <p>{date}</p> 
                  <p>{description}</p>
                </div>

                <div className='upcoming-purchase'>
                  <div className='event-upcoming-card-button'>
                    <button className='event-upcoming-card-button-button'>
                      Buy Ticket
                    </button>
                  </div>
                  <div className='purchase-price'>
                    <p>Price</p>
                    <h3>$ 100</h3>
                  </div>
                </div>
                <div className='tickets-btn'>
                  <button className='full-width-button'>
                    <div className='button-section'>
                      <span className='ticket-icon'>&#128540;</span>
                      <span className='ticket-count'>200/300</span>
                    </div>
                    <div className='button-divider'></div>
                    <div className='button-section'>
                      <span className='location-icon'>&#128205;</span>
                      <span className='location'>New York City</span>
                    </div>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EventTopbar;
