import React from "react";

const AdminProfile = () => {
  return (
    <>
      <h1>This is the admin's profile</h1>
    </>
  );
};

export default AdminProfile;
