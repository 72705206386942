import React, { useState, useEffect } from "react";
import { Table, Input, Radio } from "antd";
import axios from "axios";

export default function Users() {
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState("All");
    const { token } = JSON.parse(sessionStorage.getItem("admin"));
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        axios
            .get("/admins/list-users", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setUsers(res.data);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [token]);

    useEffect(() => {

        const filteredData = users.filter((user) => {
            const query = searchQuery.toLowerCase();
            if (query === "") {
                return true;
            }
            return (
                (user.firstName && user.firstName.toLowerCase().includes(query)) || (user.lastName && user.lastName.toLowerCase().includes(query)) ||
                (user.email && user.email.toLowerCase().includes(query)) ||
                (user.phoneNumber && user.phoneNumber.toLowerCase().includes(query))
            );
        });

        const filteredUsersByFilter = filteredData.filter((user) => {
            if (filter === "All") {
                return true;
            } else if (filter === "Google") {
                return user.googleId !== null;
            } else if (filter === "Other") {
                return user.googleId == null;
            }
            return true;
        });

        setFilteredUsers(filteredUsersByFilter);
    }, [searchQuery, users, filter]);


    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            width: 50,
            render: (_, __, index) => index + 1,
        },
        {
            title: "Profile",
            render: (text, record) => {
                return (
                    <div
                        style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            src={record.image}
                            alt="No Phone Number"
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                    </div>
                );
            }
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName"
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (text, record) => {
                if (!text && record.googleId) {
                    return <span>{record.googleId}</span>;
                }
                return <span>{text}</span>;
            },
        },
        {
            title: "Phone",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            render: (text) => {
                if (text) {
                    return <span>{text}</span>;
                }
                return <span>...</span>;
            },
        },
    ];


    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "20px" }}>
                <Radio.Group
                    options={["All", "Google", "Other"]}
                    optionType="button"
                    buttonStyle="solid"
                    defaultValue="All"
                    onChange={handleFilterChange}
                />
                <Input style={{ maxWidth: "300px" }} placeholder="Search by Name, Email, or Phone" onChange={handleSearch} />
            </div>
            <Table dataSource={filteredUsers} columns={columns} />
        </>
    );
}
