import React, { Fragment } from "react";
import Collapsible from "react-collapsible";
import pic from "../../images/ali-morshedlou-wmd64tmfc4k-unsplash.webp";
import Question from "../../Detail/Accordion";
import "../../CSS/IndexPage//Accordion.css";

const Accordion = () => {
  const detail = Question;

  return (
    <Fragment>
      <section className="accordion">
        <div className="accordion-row">
          <div className="left">
            <h3>Frequently Asked Questions</h3>
            {detail.map((detail) => {
              return (
                <div className="left-content" key={Math.random()}>
                  <Collapsible
                    className="Collapsible"
                    trigger={[
                      <i className="fa-solid fa-angle-down"></i>,
                      detail.question,
                    ]}
                    triggerWhenOpen={[
                      <i className="fa-solid fa-angle-up"></i>,
                      detail.question,
                    ]}
                  >
                    <p className="content-show">{detail.answer}</p>
                  </Collapsible>
                </div>
              );
            })}
          </div>
          <div className="right">
            <img loading="lazy" src={pic} alt="person" />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Accordion;
