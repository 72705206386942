const questions = [
  {
    id: "1",
    question: "How much do I have to pay for the test?",
    answer:
      "The test is normally made available for free, this is a service-oriented product, your comfort is our top-most priority.",
  },
  {
    id: "2",
    question: "Can I directly talk with a an expert?",
    answer: "Yes, you can reach us through the contact us tab.",
  },
  {
    id: "3",
    question: "What is the information you need to submit?",
    answer:
      "We work on a need-based information gathering system, so we need only the bare minimum information. Your privacy is our priority",
  },
];
export default questions;
