import React, { Fragment, useRef, useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "../../CSS/IndexPage/Counter.css";

const Counter = ({ className, ...rest }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const elementRef = useRef(null);

  const handleChange = (isVisible) => {
    if (isVisible) {
      setViewPortEntered(true);
    }
  };

  return (
    <Fragment>
      <section className='counter' ref={elementRef}>
        <div className='counter-container'>
          <div className='counter-row'>
            {/* Column 1 */}
            <div className='counter-column'>
              <strong>
                <CountUp {...rest} start={viewPortEntered ? null : 0} end={150}>
                  {({ countUpRef }) => {
                    return (
                      <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={handleChange}
                        delayedCall
                      >
                        <span className='counter-number' ref={countUpRef} />
                      </VisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
               Doctors
              </span>
            </div>

            {/* Column 2 */}
            <div className='counter-column'>
              <strong>
                <CountUp
                  {...rest}
                  start={viewPortEntered ? null : 0}
                  end={1024}
                >
                  {({ countUpRef }) => {
                    return (
                      <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={handleChange}
                        delayedCall
                      >
                        <span className='counter-number' ref={countUpRef} />
                      </VisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Patients <br /> Served
              </span>
            </div>

            {/* Column 3 */}
            <div className='counter-column'>
              <strong>
                <CountUp {...rest} start={viewPortEntered ? null : 0} end={53}>
                  {({ countUpRef }) => {
                    return (
                      <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={handleChange}
                        delayedCall
                      >
                        <span className='counter-number' ref={countUpRef} />
                      </VisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Countries
              </span>
            </div>

            {/* Column 4 */}
            <div className='counter-column'>
              <strong>
                <CountUp
                  {...rest}
                  start={viewPortEntered ? null : 0}
                  end={27}
                  key={Math.random()}
                >
                  {({ countUpRef }) => {
                    return (
                      <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={handleChange}
                        delayedCall
                      >
                        <span className='counter-number' ref={countUpRef} />
                      </VisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Total <br /> Branches
              </span>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Counter;
