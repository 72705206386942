import React from "react";
import "../../CSS/Appointments/AvailableDates.css";

const AvailableDates = () => {
  return (
    <div className='app-availabledates'>
      <button className='availabledates'>AvailableDates</button>
    </div>
  );
};

export default AvailableDates;
