import React, { Fragment, useEffect, useState } from "react";
import "../CSS/Profile/Profile.css";
import axios from "../axios/index";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Tooltip, Button, Table, Modal, Form, Input } from "antd";

import {
  UserDeleteOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  SettingFilled,
  BellFilled,
} from "@ant-design/icons";
const { confirm } = Modal;
export default function Profile() {
  const [authorized, setAuthorized] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [filter, setFilter] = useState("Upcoming");
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [firstBlog, setFirstBlog] = useState(0);
  const [secondBlog, setSecondBlog] = useState(1);

  const handleOpenEditModal = () => {
    setIsEditModalVisible(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleSaveEditModal = (values) => {
    console.log("Save edited profile:", values);
    handleCloseEditModal();
  };
  useEffect(() => {
    axios
      .get('/api/events')
      .then((res) => {
        setEventData(res.data);
      })
      .catch(() => {
        setEventData(null);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/blogs")
      .then((res) => {
        setBlogs(res.data);
      })
      .catch(() => {
        setBlogs([]);
      });
  }, []);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      navigate("/");
      return;
    }

    axios
      .get("/user/profile", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(() => {
        setAuthorized(true);
      })
      .catch(() => {
        navigate("/");
      });
  }, [navigate]);

  useEffect(() => {
    axios
      .get("/api/appointments", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAppointments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user.token]);

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleEdit = (appointment) => {
    const currentDate = moment().startOf("day");
    const appointmentDate = moment(appointment.date).startOf("day");
    if (appointmentDate.isBefore(currentDate)) {
      console.log("Cannot cancel appointment, date has already passed");
      return;
    }
    const updatedAppointments = appointments.map((item) => {
      if (item._id === appointment._id) {
        return { ...item, isCancel: true };
      }
      return item;
    });

    setAppointments(updatedAppointments);
  };
  const columns = [
    {
      title: "Doctor's Name",
      dataIndex: "doctorName",
      key: "doctorName",
      // width: 250,
      ellipsis: true,
    },
    {
      title: "Message",
      dataIndex: "reason",
      key: "reason",
      // width: 250,
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // width: 200,
      ellipsis: true,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      // width: 200,
      ellipsis: true,
      render: (time) => (
        <Tooltip placement='topLeft' title={time}>
          {time}
        </Tooltip>
      ),
    },
    {
      title: "Cancel",
      dataIndex: "cancel",
      key: "phone",

      render: (_, record) => (
        <>
          <Tooltip placement='topRight' title={record.phone}>
            <Button
              size='small'
              type='primary'
              onClick={() => alert(record.date)}
            >
              <PhoneOutlined />
            </Button>
            &ensp;
          </Tooltip>
          {!record.isCancel && (
            <Tooltip placement='topRight' title='Cancel Appointment'>
              <Button
                size='small'
                type='primary'
                style={{
                  backgroundColor: "#fdae5c",
                  borderColor: "#fdae5c",
                }}
                onClick={() => handleEdit(record)}
              >
                <UserDeleteOutlined />
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const filteredData = appointments.filter((item) => {
    const currentDate = moment().startOf("day");

    if (filter === "Cancelled") {
      return item.isCancel;
    } else if (filter === "Past") {
      const appointmentDate = moment(item.date);
      return appointmentDate.isBefore(currentDate);
    } else if (filter === "Upcoming") {
      const appointmentDate = moment(item.date);
      return (
        appointmentDate.isSameOrAfter(currentDate) && item.isCancel !== true
      );
    }

    return true;
  });

  const buttonOptions = [
    { label: "Upcoming", value: "Upcoming" },
    { label: "Past", value: "Past" },
    { label: "All", value: "All" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setFirstBlog((prevIndex) => (prevIndex + 1) % blogs.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [blogs]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondBlog((prevIndex) => (prevIndex + 1) % blogs.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [blogs]);

  function truncateWords(content, wordLimit) {
    if (!content) {
      return "";
    }

    const words = content.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return content;
  }
  return (
    authorized && (
      <Fragment>
        <div className='main-profile'>
          {" "}
          <div className='profile-banner'></div>
          <div className='profile-top'>
            <div className='profile-container'>
              <div className='profile-image'>
                <img src={user.image} alt={user.firstName} />
                <label htmlFor='image-upload'>
                  <span style={{ color: "#fdae5c", cursor: "pointer" }}>
                    <PlusCircleOutlined />
                  </span>
                </label>
              </div>
              <div className='profile-details'>
                <h3>
                  {user.firstname} {user.lastname}
                </h3>
                <p>{user.email}</p>
                <p>{user.phoneNumber}</p>
              </div>
            </div>
            <div className='notification'>
              <span className='notification-icon'>
                <BellFilled />
              </span>
              <span class='settings-icon' onClick={handleOpenEditModal}>
                <SettingFilled />
              </span>
            </div>
          </div>
          <section className='fetching-appointment'>
            <div className='fetching-pagination-apt'>
              <div>
                {buttonOptions.map((option) => (
                  <Button
                    key={option.value}
                    type={filter === option.value ? "primary" : "default"}
                    style={{
                      backgroundColor:
                        filter === option.value ? "#fdae5c" : "transparent",

                      marginRight: "8px",
                    }}
                    onClick={() => handleFilterChange(option.value)}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
            </div>

            <div className='profile-content'>
              <div className='profile-content-main'>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {record.description}
                      </p>
                    ),
                  }}
                />
              </div>
            </div>
          </section>
          <section className='blog-event'>
            <div className='profile-body'>
              <div className='profile-blog'>
                <div className='body-content'>
                  <div className='blog-card'>
                    <div className='blog-card-image'>
                      <img
                        src='https://shorturl.at/cFGH5'
                        alt={blogs[firstBlog]?.image}
                      />
                    </div>
                    <div className='blog-card-content'>
                      <h3 className='blog-card-title'>
                        {blogs[firstBlog]?.title}
                      </h3>
                      <p className='blog-card-text'>
                        {truncateWords(blogs[firstBlog]?.content, 10)}
                      </p>
                      <button className='card-button'>
                        <a href='#' className='blog-card-button'>
                          Read More
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='body-content'>
                  <div className='blog-card'>
                    <div className='blog-card-image'>
                      <img
                        src='https://shorturl.at/cFGH5'
                        alt={blogs[secondBlog]?.image}
                      />
                    </div>
                    <div className='blog-card-content'>
                      <h3 className='blog-card-title'>
                        {blogs[secondBlog]?.title}
                      </h3>
                      <p className='blog-card-text'>
                        {truncateWords(blogs[secondBlog]?.content, 10)}
                      </p>
                      <button className='card-button'>
                        <a href='#' className='blog-card-button'>
                          Read More
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='profile-event'>
              <div className='event-body-content'>
                {eventData && (
                  <div className='event-card'>
                    <div
                      className='event-card-image'
                      style={{ position: "relative" }}
                    >
                      <img
                        src='https://shorturl.at/vwyER'
                        alt={eventData.image}
                      />
                      <div
                        className='event-card-overlay'
                        style={{ position: "absolute", top: 0, left: 0 }}
                      >
                        <h2
                          className='event-card-title'
                          style={{ color: "#ffffff", fontSize: "25px" }}
                        >
                          {" "}
                          <h1>the title{eventData.title}</h1>
                        </h2>
                        <p
                          className='event-card-date'
                          style={{ color: "#ffffff", fontSize: "20px" }}
                        >
                          {moment(eventData.date).format("DD-MM-YYYY")}
                        </p>
                        <p
                          className='event-card-description'
                          style={{ color: "#ffffff", fontSize: "18px" }}
                        >
                          {" "}
                          hi this is event for {eventData.description}
                        </p>
                        <button className='card-button'>Book Now</button>
                      </div>
                    </div>
                  </div>
                )}
                {!eventData && (
                  <div className='event-placeholder'>
                    <p>No upcoming events</p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Modal
          title='Edit Profile'
          visible={isEditModalVisible}
          onCancel={handleCloseEditModal}
          onOk={handleSaveEditModal}
          okText='Save'
          width={400}
        >
          <Form.Item className='modal-form'>
            <span className='in-modal'>
              <img src={user.image} alt={user.firstName} />
            </span>
            <span>
              <button> Upload </button>
              <button> Remove</button>
            </span>
          </Form.Item>
          <Form.Item label='Name'>
            <Input placeholder='Enter name' />
          </Form.Item>
          <Form.Item label='Email'>
            <Input placeholder='Enter email' />
          </Form.Item>
          <Form.Item label='Mobile'>
            <Input placeholder='Enter mobile' />
          </Form.Item>
        </Modal>
      </Fragment>
    )
  );
}
